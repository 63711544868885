import { barchart } from './properties/barchart'
import { piechart } from './properties/piechart'
import { tariff_piechart } from './properties/tariff_piechart'
import { total_length_piechart } from './properties/total_length_piechart'
import { client_barchart } from './properties/client_barchart'
import { profitExpense } from './properties/profitExpense'


export const state = {
    barchart: barchart,
    piechart: piechart,
    tariff_piechart: tariff_piechart,
    total_length_piechart: total_length_piechart,
    client_barchart: client_barchart,
    profitExpense: profitExpense,
};
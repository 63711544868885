import request from '@/utils/request';

export function show(params) {
    return request({
        url: `/dealProducts/${params.id}`,
        method: 'get',
        params
    })
}

export function destroy(id) {
    return request({
        url: `/dealProducts/${id}`,
        method: 'delete'
    })
}

export function deleteParcelProduct(id) {
    return request({
        url: `/dealProducts/deleteParcelProduct/${id}`,
        method: 'delete'
    })
}
import request from '@/utils/request'

export function createSessionForPayment(data) {
    return request({
        url: '/stripe/create-checkout-session',
        method: 'post',
        data
    })
}

export function index(params) {
    return request({
        url: '/payments',
        method: 'get',
        params
    })
}

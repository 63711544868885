export const ru_messages = {
  edit: "Изменить",
  delete: "Удалить",
  assign_role:"Назначить роль",
  orders: "Заказы",
  order: "Заказ",
  create: "Создать",
  accept: "Принять",
  logout: "Выйти",
  users: "Пользователи",
  search: "Поиск",
  from: 'От',
  to: 'До',
  name: 'Наименование',
  phone: 'Телефон',
  role: 'Роль',
  status: 'Статус',
  first_name: 'Имя',
  surname: 'Фамилия',
  created_at: 'Дата создания',
  updated_at: 'Дата изменения',
  settings: 'Настройки',
  list: 'Список',
  user: 'пользователь',
  client: 'клиент',
  driver: 'водитель',
  transport: 'транспорт',
  cost_length: 'длина стоимости',
  total_length: 'общая длина',
  exit_time: 'время выхода',
  enter_time: 'время входа',
  tariff: 'тариф',
  new: 'новый',
  save: 'Сохранить',
  close: 'Закрыть',
  length: 'Пробег',
  password: 'Пароль',
  'Middle name': 'Отчество',
  roles: 'Роли',
  slug: 'Слизень',
  login: 'Авторизоваться',
  loading: 'Загрузка...',
  save_and_exit: 'Сохранить и закрыть',
  'Last login': 'Последний логин',
  email: 'Эл. адрес',
  active: 'Активный',
  deactive: 'Нет активных',
  client: 'Клиент',
  driver: 'Водитель',
  address: 'Адрес',
  Transport: 'Транспорт',
  Tariff: 'Тариф',
  cost: 'Стоимость',
  cost: 'стоимость',
  yes: 'Да',
  cancel: 'Отменить',
  no: 'Нет',
  'current length': 'текущая длина',
  after_enter_length: 'Пробег после прибытия',
  total_driven_length: 'Общая пройденная длина',
  cashbox: 'Касса',
  transactions: 'Платежи',
  cost_transactions: 'Платежи за расходы',
  incoming_payment: 'Входящий платеж',
  paymentTypes: 'Тип оплаты',
  amount: 'Сумма',
  comments: 'Комментарий',
  n: '№',
  'Bind Payment': 'Привязать платеж',
  total_amount: 'Общая сумма',
  paid: 'Оплачено',
  'Paid from this payment': 'Оплачено из этого платежа',
  delete_confirm: 'Вы уверены, что хотите удалить данный элемент?',
  confirm: 'Подтверждение',
  snap: 'Привязать',
  expense_types: 'Типы расходов',
  entity: 'Юр лицо',
  individual: 'Физ лицо',
  repeat_password: 'Повторный пароль  ',
  country: 'Страна',
  patronymic: 'Отчество',
  please_enter_input: 'Пожалуйста, введите {input}',
  please_select_input: 'Пожалуйста, выберите {input}',
  input_correct_email: 'Пожалуйста, введите правильный адрес электронной почты',
  length_must_be_min: 'Длина должна быть минимум {number} характеров',
  length_must_be_max: 'Длина должна быть максимум {number} характеров ',
  type:"Тип",
  company_name: 'Название компании',
  'There is a problem on the server side': 'Есть проблема на стороне сервера',
  error_m: 'Ошибка',
  code:"Код",
  accepted_by_me:"Принимаю",
  rules_of_service: 'Условия оказания услуг',
  register: 'Регистрация',
  please_accept_terms_for_register: 'Пожалуйста, примите Условия оказания услуг для регистрации',
  profile: 'Профиль',
  fio: 'Ф.И.О',
  country:"Страна",
  countries:"Страны",
  region: 'Область (Регион)',
  regions: 'Области (Регион)',
  city:"Город",
  cities:"Города",
  city_id:"Город",
  country_id:"Страна",
  product:"Продукт",
  products:"Продукты",
  reciever: 'Получатель',   
  passport_given_by: 'Кем выдан',   
  passport_given_date: 'Дата выдачи',   
  passport_series: 'Серия паспорта',   
  passport_number: "Номер паспорта", 
  no_more_data: 'Больше нет данных',
  add_address: 'Добавить еще адрес',
  street: 'Улица',   
  house: 'Дом',   
  flat: 'Квартира', 
  landmark: 'Ориентир',
  zip_code: 'Zip код (индекс)', 
  is_default: "По умолчанию",
  unable_to_delete_last_form: 'У вас должен быть как минимум 1 доступный адрес для создания сделки!',
  warning: "Предупреждение",
  reciever: 'Получатель',   
  recievers: 'Получатели',   
  reciever_address: 'Адрес получателя',
  all_deals: 'Все заявки',
  deals: "Заявки",
  deal: "Заявка",
  client_balance: "Баланс клиента",
  remainder_for_client_balance: "Остаток для клиентского баланса",
  enter_money_correctly: "Введите сумму правильно",
  payment_from_client_balance: "Оплата с баланса клиента",
  cash_register: 'Касса',
  balance: 'Баланс',
  marketplace: 'Marketplace',
  parcels: 'Посылки',
  cargo_type: 'Тип груза',
  cargo: 'Груз',
  cargo_name: 'Название груза',
  parcel: 'Посылка',
  parcel_weight_limit : 'Вес посылки превышает разрешенный веса. Пожалуйста создайте другой тип заказа.',   
  parcel_size_limit: 'Сумма габаритов превышает разрешенных {size} {measure}. Пожалуйста создайте другой тип заказа.',
  services: 'Услуги',
  service: 'Услуга',
  percent: 'Процент',
  for_site: 'Актив для сайта',
  for_systeam: 'Актив для системы',
  registerRule: 'Правила регистрации',
  registerRules: 'Правила регистрации',
  rules: 'Правила',
  dashboard: 'Главная',
  company_addresses: 'Адреса компании',
  show_more: 'Показать еще',
  new_deal: 'Новая Заявка',
  commerce_deal: 'Коммерческий груз',
  recieving_address: 'Адрес доставки',
  direction: 'Направление',
  price: 'Стоимость',
  total_sum: 'Итого',
  deal_create: 'Оформить заказ',
  deal_update: 'Обновить заказ',
  top_up_balance: 'Пополнить баланс',
  top_up: 'Пополнить',
  add_product: 'Добавить продукт',
  cargo_weight: 'Общий вес груза (кг)',
  dimensions: 'Габариты',
  weight: "Масса (кг)",
  width: "Ширина (см)",
  length: "Длина (см)",
  height: "Высота (см)",
  qty: 'Количество',
  shipping_cost: 'Стоимость доставки',
  cant_delete_last_product: 'Нельзя удалить последний товар!',
  successful: 'Успешный',
  phone_number_is_not_valid: 'Пожалуйста, введите правильный номер. Номер телефона неверен!',
  operation_canceled: 'Операция отменена!',
  do_you_want_to_delete: 'Вы действительно хотите удалить это?',
  parcel_weight_limit_message : 'Вес посылки превышает разрешенный вес 30 кг. Сумма габаритов не должна превышать 300 см. Пожалуйста создайте другой тип заказа.',   
  parcel_weight_limit : 'Посылка не должна превышать вес 30 кг. Сумма габаритов не должна превышать 300 см.',  
  parcel_size_limit: 'Сумма габаритов превышает разрешенных {size} {measure}. Пожалуйста создайте другой тип заказа.',
  weight_cant_be_0: 'Вес не может быть 0 !',   
  enter_product_name: 'Название продукта не может быть пустым !',
  comment: 'Комментарий',
  product_category: 'Категория продукта',
  journal: 'Журнал',
  tracking_code: 'Код отслеживания',
  all_weight: "Общий вес",
  view: "Просмотр",
  shipping_address: "Адрес доставки",
  kg: 'кг',
  category: 'Категория',
  qty_y: 'Кол-во',
  waiting: 'В ожидание',
  on_way: 'В пути',
  on_client: 'Доставлено ',
  more: 'Подробнее',
  direction_not_found: 'Направление не найдено, Пожалуйста, выберите направление!',
  ready_to_send_client: "Готов к выдаче",
  add: 'Добавить',
  reciever_cridentials: 'Данные получателя',
  deal_numer: 'Заявка No {number}',
  reason: 'Причина',
  do_you_really_want_to_do_this: 'Вы действительно хотите это сделать?',
  prohibited_goods: 'Запрещенные товары !',
  'Are you sure you want to uninstall the product?': 'Вы действительно хотите удалить продукт?',
  deleting_product_canceled: 'Удаление продукта отменено',
  additional: 'Дополнительный',
  deal_info: 'Данные заказа',
  clientTypes: 'Статус',
  your_living_address: 'Укажите адрес получателя',
  tracking_code_helps_to_go_shipment_faster: 'Ввод трэк-номера обязателен. Трэк-номер позволит с высокой точностью и быстро зарегистрировать посылку.',
  if_your_country_not_exist_contact_us: "Если вашей страны нет в списке, свяжиь с нашим оператором.",
  enter_weight_of_parcel:  'Заполнение веса посылки не обязательно и служит для расчета приблизительной стоимости доставки.',
  deal_total: 'Общая стоимость заявки',
  shipping_service: "Службы доставки",
  shipping_to_address: 'Доставка до двери',
  text_length_must_be: 'Длина должна быть от {min} до {max} (пр: +19171234567)',
  forgot_password: 'Забыли пароль?',
  password_reset: 'Сброс пароля',
  login_system: 'Войти',
  new_password: 'Новый пароль',
  confirm_password: 'Повторите пароль',
  please_do: 'Пожалуйста, {do}',
  invalid_route: 'Неверная ссылка',
  passwords_dont_match: 'Пароли не совпадают',
  send_email: "Отправить Email",
  pay_now: 'Заплатить',
  something_went_wrong_try_again: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз.',
  currency: "Валюта",
  bankAccounts: 'Банковские счета',
  bankAccount: 'Банковский счет',
  paymentType: "Тип оплаты",
  money_amount: "Сумма",
  payments: 'Платежи',
  payment: 'Платеж',
  back: 'Назад',
  please_enter_phone: 'Пожалуйста, введите Телефон (пр: +19171234567)',
  passport_iin: 'ИИН',
  passport: 'Паспорт',
  identification_type: 'Тип идентификации',
  weight_limit_text: 'Если вес груза составляет меньше 1 кг, то стоимость за доставку рассчитывается как за 1 кг.',
  commercial_product_weight_cant_be_exceed: "Минимальный вес коммерческого груза 5 кг! Если вес вашего груза меньше указанного, вы можете создать посылку",
  commercial_product_min_weight_limit: "Минимальный вес коммерческого груза 5 кг!",
  invoice: 'НАКЛАДНАЯ',
  payment_for_deal: 'ОПЛАТА ЗА ПОСЫЛКУ',
  linkCopied: 'Ссылка скопирована',
  referral_users_count: 'К-во рефералов',
  referral_users: 'Рефералы',
  bonus: 'Бонус',
  date: 'Дата'

}

<template>
  <div>
    <div class="cardMain bgMain">
      <h3 class="colorMain">
        {{ $t('message.profile') }}
      </h3>

      <el-form ref="form" :model="form" :rules="rules" label-position="top">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item prop="name" :label="columns.name.title">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
             <!-- end  el-col -->

             <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <crm-phone-input
                :inputValue="form.phone" 
                v-model="form.phone"
                @getPhoneStatus="getPhoneStatus"
                :form_prop="'phone'"
                :show_label="true"
                :country_code="form.phone_country_code"
              ></crm-phone-input> 
            </el-col>
            <!-- end  el-col -->

            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item prop="country_id" :label="columns.country_id.title">
                <inventory-select
                  v-bind:key="'countries'"  
                  :unique_key="'countries'"
                  :query="{has_code: true}"
                  :placeholder="$t('message.country')"
                  :id="form.country_id"
                  :action_key="'countries'"
                  v-model="form.country_id"
                  >
                </inventory-select>
              </el-form-item>
            </el-col>
            <!-- end  el-col -->

            <el-col v-if="form.country_id && form.country_id == KZ_COUNTRY_ID" :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item :label="$t('message.identification_type')">
                <el-switch
                  v-model="form.has_iin"
                  :active-text="$t('message.passport_iin')"
                  :inactive-text="$t('message.passport')">
                </el-switch>
              </el-form-item>
            </el-col>

            <el-col v-if="form.has_iin" :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item prop="passport_iin" :label="$t('message.passport_iin')">
                <el-input v-model="form.passport_iin"></el-input>
              </el-form-item>
            </el-col>

            <el-col v-if="!form.has_iin" :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item prop="passport_series" :label="columns.passport_series.title">
                <el-input v-model="form.passport_series"></el-input>
              </el-form-item>
            </el-col>
            <!-- end  el-col -->

            <el-col v-if="!form.has_iin" :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item prop="passport_number" :label="columns.passport_number.title">
                <el-input v-model="form.passport_number"></el-input>
              </el-form-item>
            </el-col>
            <!-- end  el-col -->

            <el-col v-if="!form.has_iin" :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item prop="passport_given_by" :label="columns.passport_given_by.title">
                <el-input v-model="form.passport_given_by"></el-input>
              </el-form-item>
            </el-col>
            <!-- end  el-col -->

            <el-col v-if="!form.has_iin" :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item prop="passport_given_date" :label="columns.passport_given_date.title">
                <el-date-picker
                  v-model="form.passport_given_date"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :placeholder="columns.passport_given_date.title">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <!-- end  el-col -->
          </el-row>

          <el-row :gutter="20" v-for="(address, index) in form.addresses" :key="index">
            <el-divider content-position="left"> {{ $t('message.address') }} № {{ index+1 }} </el-divider>
            <div class="text-center font-weight-bold mb-2">{{ $t('message.your_living_address') }}</div>
            <div>
              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <el-form-item 
                  :label="columns.country_id.title" 
                  :prop="'addresses.' + index + '.country_id'"
                  :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.country')}), trigger: 'change' }">
                    <inventory-select
                      :unique_key="'countries_'+index"
                      :placeholder="$t('message.country')"
                      :id="address.country_id"
                      :action_key="'countries'"
                      v-model="address.country_id"
                      :disabled="address.deal_addresses_count > 0"
                      >
                    </inventory-select>
                </el-form-item>
              </el-col>
              <!-- end  el-col -->

              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <el-form-item :label="$t('message.region')"
                  :prop="'addresses.' + index + '.region_id'"
                  :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.region')}), trigger: 'change' }"
                >
                  <inventory-select
                    :unique_key="'regions_'+index"
                    :placeholder="$t('message.region')"
                    :id="address.region_id"
                    :action_key="'regions'"
                    v-model="address.region_id"
                    :required="['country_id']"
                    :query="{country_id: address.country_id, city_id: address.city_id}"
                    :disabled="address.deal_addresses_count > 0"
                    >
                  </inventory-select>
                </el-form-item>
              </el-col>
              <!-- end  el-col -->

              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <el-form-item :label="columns.city_id.title"
                  :prop="'addresses.' + index + '.city_id'"
                  :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.city')}), trigger: 'change' }"
                >
                  <inventory-select
                    :unique_key="'cities_'+index"
                    :placeholder="$t('message.city')"
                    :id="address.city_id"
                    :action_key="'cities'"
                    v-model="address.city_id"
                    :required="['country_id']"
                    :query="{country_id: address.country_id, region_id: address.region_id}"
                    :disabled="address.deal_addresses_count > 0"
                    >
                  </inventory-select>
                </el-form-item>
              </el-col>
              <!-- end  el-col -->

              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <el-form-item :label="$t('message.street')"
                  :prop="'addresses.' + index + '.street'"
                  :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.street')}), trigger: 'change' }"
                >
                  <el-input v-model="address.street" :disabled="address.deal_addresses_count > 0" :placeholder="$t('message.street')"></el-input>
                </el-form-item>
              </el-col>
              <!-- end  el-col -->

              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <el-form-item :label="$t('message.house')" 
                  :prop="'addresses.' + index + '.house'"
                  :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.house')}), trigger: 'change' }"
                >
                  <el-input v-model="address.house" :disabled="address.deal_addresses_count > 0" :placeholder="$t('message.house')"></el-input>
                </el-form-item>
              </el-col>
              <!-- end  el-col -->

              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <el-form-item :label="$t('message.flat')">
                  <el-input v-model="address.flat" :disabled="address.deal_addresses_count > 0" :placeholder="$t('message.flat')"></el-input>
                </el-form-item>
              </el-col>
              <!-- end  el-col -->

              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <el-form-item :label="$t('message.landmark')">
                  <el-input v-model="address.landmark" :disabled="address.deal_addresses_count > 0" :placeholder="$t('message.landmark')"></el-input>
                </el-form-item>
              </el-col>
              <!-- end  el-col -->

              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <el-form-item :label="$t('message.zip_code')">
                  <el-input v-model="address.zip_code" :disabled="address.deal_addresses_count > 0" :placeholder="$t('message.zip_code')"></el-input>
                </el-form-item>
              </el-col>
              <!-- end  el-col -->

              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <div class="d-flex">
                  <el-form-item class="w-100" :label="$t('message.is_default')">
                    <div class="d-flex w-100" style="justify-content: space-between;">
                      <el-checkbox 
                        v-model="address.is_default" 
                        :label="$t('message.is_default')"  
                        @change="updateOthers(index)"
                        :class="address.is_default ? 'mm_checked_address' : ''"  
                        :disabled="address.is_default" 
                        :border="true">
                      </el-checkbox>
                      <el-button v-if="address.deal_addresses_count == 0" @click="removeAddress(address, index)" icon="el-icon-delete-solid" class="action-btn ml-2" size="medium"></el-button>
                    </div>
                  </el-form-item>
                </div>
              </el-col>
              <!-- end  el-col -->

              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                <el-button v-if="((index+1) === form.addresses.length)" @click="addAddress()" class="action-btn" style="margin-top: 25px" round>
                  {{ $t('message.add_address') }}
                </el-button>
              </el-col>
              <!-- end  el-col -->
            </div>
          </el-row>
          <div  class="dialog-footer">
            <el-button @click="save()" type="success" round> {{$t('message.save')}} </el-button>
          </div>
      </el-form>
 
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import inventorySelect from "@/includes/selects/inventory-select";
import drawer from '@/utils/mixins/drawer';
import crmPhoneInput from "@/includes/selects/crm-phone-input";

export default {
  name: 'parcel',
  mixins: [drawer],
  components: {
    inventorySelect,
    crmPhoneInput
  },
  props: {
    selectedProfile: {
      type: Object
    },
    
  },
  data() {
      return {
        is_phone_valid: false,
        form: {},
        loading: false,
      };
  },
  created(){
    this.form = JSON.parse(JSON.stringify(this.model));
  },
  computed: {
    ...mapGetters({
      settings: "auth/settings",
      authUser: "auth/user",
      model: "profile/model",
      columns: "profile/columns",
      rules: "profile/rules",
    }),
    KZ_COUNTRY_ID(){
      return (this.settings && this.settings.iin_countries ? this.settings.iin_countries : 0);
    },
  },

  methods: {
      ...mapActions({
        update: "profile/update",
        profile: "profile/profile",
        deleteClientAddress: "clients/deleteClientAddress"
      }),
      getPhoneStatus({is_valid, countryCode}){
        this.is_phone_valid = is_valid;
        this.form.phone_country_code = countryCode;
      },
      afterOpen(){
        this.loading = true;
        this.profile(this.selectedProfile.id)
          .then(res => {
            this.form = JSON.parse(JSON.stringify(this.model));
            this.loading = false;
          }).catch(err => this.loading = false);
      },
      save(){
        this.$refs["form"].validate((valid) => {
          if (valid && this.is_phone_valid) {
            this.loading = true;
            this.update(this.form)
              .then(res => {
                this.loading = false;
                this.$alert(res);
                this.close();
                this.parent().fetchData();
              }).then(err => this.loading = false);
          }else if(!this.is_phone_valid){
            this.$notify({
                title: this.$t('message.warning'),
                type: "warning",
                offset: 130,
                message: this.$t('message.phone_number_is_not_valid')
            });
          }
        });
      },
      close(){
        this.parent().updateRecipient = false;
      },
      removeAddress(address, index) {
        this.$confirm(this.$t('message.do_you_want_to_delete'), this.$t('message.warning'), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          if (this.form.addresses.length === 1) {
            this.$notify({
                title: this.$t('message.warning'),
                type: "warning",
                offset: 130,
                message: this.$t("message.unable_to_delete_last_form")
            });
          } else {
            if(address.id){
              this.deleteClientAddress(address.id)
                .then(res => {
                  this.$alert(res);
                  this.form.addresses.splice(index, 1);
                }).catch(err => {
                  this.$alert(err);
                });
            }else{
              this.form.addresses.splice(index, 1);
            }
          }
        }).catch(() => {
          this.$notify({
            title: this.$t('message.warning'),
            type: "warning",
            offset: 130,
            message: this.$t("message.operation_canceled")
          });     
        });


        
      },

      addAddress(){
        let new_address = {
          id: "",
          client_id: "",
          country_id: "",
          region_id: "",
          city_id: "",
          street: "",
          house: "",
          flat: "",
          landmark: "",
          zip_code: "",
          is_default: false,
          deal_addresses_count: 0,
        };
        this.form.addresses.push(new_address);
      },

      updateOthers(address_index) {
        this.form.addresses.forEach((item, index) => {
            if(index !== address_index){
              this.$set(item, 'is_default', false);
            }
        });
      },
  },
};
</script>

<style lang="scss" scoped>
.d-center{
display: flex;
justify-content: center;
align-items: center;
}
</style>
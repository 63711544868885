<template>
    <div v-loading="loading">
        <el-col v-for="(sort, index) in filterOptions" :key="index" :xs="24" :sm="6" :md="6" :lg="4" :xl="4">
            <el-button class="w-100 mb-10" 
                @click="updateActive(sort.action)" 
                :class="(active == sort.action) ? 'action-btn' : ''" 
                round>
                    {{ sort.title }} - {{ sort.count }}
            </el-button>
        </el-col>
    </div>
</template>

<script>
import { mapGetters, mapActions} from 'vuex'
export default {
    name: 'filterTable',
    data: () => ({
        active: 'new_deals',
        loading: false,
    }),
    watch: {
        active: {
            handler: async function(newVal, oldVal) {
                if(newVal) this.$emit('updateActiveFilter', newVal);
            },
            deep: true,
            immediate: true
        },
    },
    created() {
        this.loading = true;
        this.updateFilterOptions({account: true})
            .then(res => this.loading = false)
            .catch(err => this.loading = false);
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            filterOptions: "deals/filterOptions",
        }),
    },
    methods: {
        ...mapActions({
            updateFilterOptions: "deals/getFilterOptions",
        }),
        updateActive (action) {
            this.active = action
        },
        
    }
}
</script>

<style>
.mew-style-khan{
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 99%;
}
</style>

import {i18n} from '@/utils/i18n';

export const passport_rules = {
    passport_series: [
        {
            required: true,
            message: i18n.t('message.please_enter_input', {input: i18n.t('message.passport_series')}),
            trigger: "change"
        }
    ],
    passport_number: [
        {
            required: true,
            message: i18n.t('message.please_enter_input', {input: i18n.t('message.passport_number')}),
            trigger: "change"
        }
    ],
    passport_iin: [
        {
            required: true,
            message: i18n.t('message.please_enter_input', {input: i18n.t('message.passport_iin')}),
            trigger: "change"
        }
    ],
}
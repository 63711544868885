import router from './router/index'
import { getToken, setToken, removeToken } from './utils/auth' // get token from cookie
import store from "./store/index";
import { Notification } from 'element-ui'
import getPageTitle from './utils/get-page-title'

router.beforeEach(async(to, from, next) => {
    // determine whether the user has logged in
  document.title = getPageTitle(to.meta.title);
  const hasToken = (store.getters.token != null) ? store.getters.token : getToken();
  let login_token = to.query.token ? to.query.token : '';
  if (hasToken && !(login_token && to.path == '/')) {         
    if (to.path === '/login') {
        // if is logged in, redirect to the home page
        next({ path: '/' })
    } else {
        // determine whether the user has obtained his permission roles through getInfo
        const hasRole = store.getters.role;
        
        if (hasRole && hasRole != undefined) {
            const authUser = store.getters.user;
            if((to.path == '/profil') || (authUser.client && authUser.client.country_id && authUser.phone && authUser.name && authUser.address_count > 0)){
              next();
            }else{
              next(`/profil?redirect=${to.path}`)
            }
        } else {
            try {
                // get user info
                // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
                await store.dispatch('auth/getAuth')
                    .then(res => {})
                    .catch(async(err) => {
                        next(`/login?redirect=${to.path}`)
                        await store.dispatch('auth/resetToken')
                    })
                const authUser = store.getters.user;
                if((to.path == '/profil') || (authUser.client && authUser.client.country_id && authUser.phone && authUser.name && authUser.address_count > 0)){
                  next();   
                }else if(authUser){
                  next(`/profil?redirect=${to.path}`)
                }else{
                  next(`/login?redirect=${to.path}`)
                }
            } catch (error) {
                // remove token and go to login page to re-login                    
                await store.dispatch('auth/resetToken')
                Notification.error(error || 'Has Error')
                next(`/login?redirect=${to.path}`)
            }
        }
    }
  } else {
    /* has no token*/
    if(login_token && !to.path.includes("passwordReset")){
      // setToken(login_token);
      store.commit('auth/SET_TOKEN', '');
      removeToken();
      setTimeout(() => {
        setToken(login_token);
        store.commit('auth/SET_TOKEN', login_token);
      }, 200);
      setTimeout(() => {
        next({ path: '/profil' });
      }, 400);
    }else {
      let token = to.params.token ? to.params.token : '';
      let referral_secret = to.params.referral_secret ? to.params.referral_secret : '';
      if (['/login', `/login/${referral_secret}`, '/conditions', `/passwordReset/${token}`].includes(to.path)) {
        // in the free login whitelist, go directly
        next()
      } else {
        // other pages that do not have permission to access are redirected to the login page.
        next(`/login?redirect=${to.path}`)
      }
    }
    
  }
});

router.afterEach(() => {
  // finish progress bar
});

import request from '@/utils/request'

export function show(id) {
    return request({
        url: `/services/${id}`,
        method: 'get',
    })
}

export function deleteDealServiceItem(id) {
	return request({
		 url: `/services/deleteDealServiceItem/${id}`,
		 method: 'delete',
	})
}
import request from '@/utils/request';

export function updateProductPricesByTariff(data) {
    return request({
        url: '/productTariffPrices/updateProductPricesByTariff',
        method: 'put',
        data
    })
}

export function updatePartnerPricesByTariff(data) {
    return request({
        url: '/partnerTariffPrices/updatePartnerPricesByTariff',
        method: 'put',
        data
    })
}

export function getWithTariffPrice(params) {
    return request({
        url: "/products/getWithTariffPrice",
        method: "get",
        params
    });
}

export function show(id) {
    return request({
        url: `/products/${id}`,
        method: 'get'
    })
}
import { getInventoryItems, client_status, senderFilials, clientAddresses} from "@/api/inventories";

export const actions = {
    getInventoryItems({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getInventoryItems(params).then(res => {
                commit("SET_TO_INVENTORY", {data: res.data.result.data[params.action_key], key: params.action_key});
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },
    clientAddresses({ commit }, id) {
        return new Promise((resolve, reject) => {
            clientAddresses(id).then(res => {
                commit("CLIENT_ADDRESSES", res.data.result.data);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    senderFilials({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            senderFilials(params).then(res => {
                commit("SET_FILIALS", res.data.result.data);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    addInventoryItems({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getInventoryItems(params).then(res => {
                commit("ADD_TO_INVENTORY", {data: res.data.result.data[params.action_key], key: params.action_key});
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },


    client_status({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            client_status(params).then(res => {
                commit("SET_CLIENT_STATUS_INVENTORY", res.data.result.data.types);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },


};

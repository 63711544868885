export const model = {
    id: null,
    name: '',
    first_name: '',
    phone: '',
    surname: '',
    patronymic: '',
    role: null,
    email: '',
    password: '',
    pin_code: '',
    verified: false,
    locked: false,
    status: true,
    is_employee: '',
    employee_groups: [],
    shop_id: null,
};

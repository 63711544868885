import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: '№',
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.fio'),
        sortable: true,
        column: 'name'
    },
    phone : {
        show: true,
        title: i18n.t('message.phone'),
        sortable: true,
        column: 'phone'
    },
    passport_number : {
        show: false,
        title: i18n.t('message.passport_number'),
        sortable: true,
        column: 'passport_number'
    },
    passport_series : {
        show: false,
        title: i18n.t('message.passport_series'),
        sortable: true,
        column: 'passport_series'
    },
    passport_given_date : {
        show: false,
        title: i18n.t('message.passport_given_date'),
        sortable: true,
        column: 'passport_given_date'
    },

    passport_given_by : {
        show: false,
        title: i18n.t('message.passport_given_by'),
        sortable: true,
        column: 'passport_given_by'
    },

    country_id : {
        show: true,
        title: i18n.t('message.country'),
        sortable: true,
        column: 'country_id'
    },

    city_id : {
        show: true,
        title: i18n.t('message.city'),
        sortable: true,
        column: 'city_id'
    },
    created_at: {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },
};

import { getToken } from "@/utils/auth";
import { register_rules } from "./properties/register_rules";
import { login_rules } from "./properties/login_rules";
import { passport_rules } from "./properties/passport_rules";
import { password_reset_rules } from "./properties/password_reset_rules";
import { new_password_setting_rules } from "./properties/new_password_setting_rules";

export const state = {
    token: getToken(),
    name: '',
    phone: '',
    email: '',
    expires_in: 600000,
    slug: '',
    status: null, 
    register_rules: register_rules,  
    login_rules: login_rules,  
    passport_rules: passport_rules,  
    password_reset_rules: password_reset_rules,  
    new_password_setting_rules: new_password_setting_rules,  
    role: '',
    settings: {},
    user:{},
    account_rule: {}
};

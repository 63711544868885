export var tariff_piechart = {
    series: [ 50, 50],
    chartOptions: {
        chart: {
            type: 'donut',
        },
        labels: ['Tariff 1', 'Tariff 2'],
        responsive: [{
            breakpoint: 480,
            options: {
            chart: {
                width: 200
            },
            legend: {
                position: 'bottom'
            }
            }
        }]
    },
}
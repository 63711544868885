import {i18n} from '@/utils/i18n';

export const register_rules = {
    type_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.type')}), trigger: 'change' },
    ],
    first_name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.first_name')}), trigger: 'change' },
    ],
    surname: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.surname')}), trigger: 'change' },
    ],
    country_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.country')}), trigger: 'change' },
    ],
    password: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.password')}), trigger: 'change' },
        { min: 4, message: i18n.t('message.length_must_be_min', {number: 4}),  trigger: "blur" },
    ],
    email: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.email')}), trigger: "blur" },
        { type: 'email', message: i18n.t('message.input_correct_email'), trigger: "blur" },
    ],
    repeat_password: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.repeat_password')}), trigger: 'change' },
        { min: 4, message: i18n.t('message.length_must_be_min', {number: 4}),  trigger: "blur" },
    ],
    confirmed_rules: [
        { required: true, message: i18n.t('message.please_accept_terms'), trigger: 'change' },
    ],
};

export const mutations = {
    SET_BEST_DRIVERS: (state, drivers) => {
        state.barchart.series[0].data = drivers.total_lengths
        state.barchart.series[1].data = drivers.cost_length
        state.barchart.chartOptions.xaxis.categories = drivers.names
    },
    SET_PROFIT_EXPENSE: (state,data) => {
        state.profitExpense.series[0].data = data.profit
        state.profitExpense.series[1].data = data.expense
        state.profitExpense.chartOptions.xaxis.categories = data.date
    },
    SET_AVERAGE_LENGTH: (state, data) => {
        state.piechart.series = data.length
        state.piechart.chartOptions.labels = data.names
    },
    SET_SELECTED_TARIFF: (state, data) => {
        state.tariff_piechart.series = data.count
        state.tariff_piechart.chartOptions.labels = data.names
    },
    SET_TOTAL_LENGTH: (state,data) => {
        state.total_length_piechart.series = data.length
        state.total_length_piechart.chartOptions.labels = data.names
    },
    SET_ACTIVE_CLIENT: (state,data) => {
        state.client_barchart.series[0].data = data.count
        state.client_barchart.chartOptions.xaxis.categories = data.names
    }
};

import { show, deleteDealServiceItem } from "@/api/services";

export const actions = {
    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.service);
                    resolve(res.data.result.data);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            deleteDealServiceItem(id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

};

<template>
  <div id="app" :class="mode ? 'l-modal-style' : 'd-modal-style'">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";
export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
    ...mapGetters({
          mode: "MODE"
    }),
  },
  components: {
    EmptyLayout,
    MainLayout,
  },
}
</script>

<style lang="scss">
// Color
.d-modal-style{
    .Suite{
      border-color: #fff;
    }
    .haeder-menu a {
      border-right-color: #fff;
    }
    .mm_background_highlighted{
      background-color: #121730;
    }
    .menu-mabil-home li a,
    .el-dialog__title, .el-tabs__item,
    .info-table p,
    .h-right-link a,
    .h-right-link,
    .colorMain,
    .haeder-menu a,
    .text__color1,
    .el-pagination__total,
    .apexcharts-title-text,
    .apexcharts-legend-text,
    .block-titles,
    .apexcharts-text,
    .modal-title-my span,
    .el-form-item__label,
    .large--title{
        color: #fff;
        fill: #fff;
    }
    .el-dialog__body, .el-dialog__header, .el-dialog__footer,
    .bgMain,
    .roles .el-tree,
    .modal-cardi-mi .el-card,
    .el-drawer,
    .menu-mabil-home,
    .template-blocks{
        background-color: #080c24 !important;
    }

    // border table

    .content_header .el-button,
    .el-input__inner,
    .el-checkbox,
    .el-drawer__body .el-input__inner,
    .el-textarea__inner
    {
        border: 1px dashed #2a2f34 !important;
        background-color: #0e2c4685 !important;
        color: #ced4da;
    }
    .my-login-card-teb,
    .style-home .el-card,
    .table,
    .table th,
    .table td{
      border: 1px dashed #2a2f34 !important;
      background-color: #080c24 !important;
      color: #ced4da;
    }
    .modal-header{
      border-bottom: 1px dashed #2a2f34;
      background-color: rgba(41,186,219,.18);
    }
    .modal-cardi-mi .el-card{
      border: 1px dashed #2a2f34;
      border-left: 5px solid#0d67c9;
    }

    .h-right-link,
    .el-pager .number,
    .el-pagination button,
    .el-pagination button:disabled{
      background-color: #0e2c4685;
      border: 1px dashed #2a2f34 ;
      border-radius: 5px;
    }
    .el-pager .number.active{
      border: 1px dashed #2a2f34 ;
      background: #0d67c9;
      color: #ced4da;
      border-radius: 5px;
    }
    & ~ .el-select-dropdown {
        border-color: #0a1c35 !important;
        .el-scrollbar {
            background-color: #0a1c35 !important;
            .el-select-dropdown__item {
                color: #fff !important;
                &.hover {
                    background-color: #232c43;
                }
                &:hover {
                    background-color: #232c43;
                }
            }
        }
        .popper__arrow,
        .popper__arrow::after {
            border-bottom-color: #0a1c35 !important;
        }
    }
    & ~ .el-picker-panel {
        background-color: #0a1c35 !important;
        border-color: #0a1c35;
        .el-date-picker__header button,
        .el-date-picker__header span {
            color: #fff;
        }

        table tbody tr .available {
            color: #fff;
        }
        table tbody tr th,
        table tbody tr td.prev-month,
        table tbody tr td.next-month {
            color: #a1a1aa !important;
        }
        .popper__arrow,
        .popper__arrow::after {
            border-bottom-color: #0a1c35 !important;
        }
    }
    & ~ .el-dropdown-menu {
        background-color: #0a1c35 !important;
        border-color: #0a1c35;
        .el-dropdown-menu__item {
            color: #fff !important;
            &.is-disabled {
                color: #a3a3a3 !important;
            }
            &:hover {
                background-color: #232c43;
            }
        }
        .popper__arrow,
        .popper__arrow::after {
            border-bottom-color: #0a1c35 !important;
        }
    }
    .img-l{
      display: none;
    }
    .img-d{
      display: block;
    }
}
.l-modal-style{
    .menu-mabil-home,
    .el-pager .number,
    .el-pagination button,
    .el-pagination button:disabled{
      background-color: #ffffff;
      border: 1px solid #dee2e6;;
      border-radius: 5px;
    }
    .el-pager .number.active{
      border: 1px solid #dee2e6;; ;
      background: #0d67c9;
      color: #ced4da;
      border-radius: 5px;
    }
    .haeder-menu a {
      border-right-color: #e9e9e9;
    }
    .mm_background_highlighted{
      background-color: #f0f0f0;
    }
    .info-table p,
    .h-right-link a,
    .h-right-link,
    .colorMain,
    .haeder-menu a,
    .text__color1,
    .el-pagination__total,
    .apexcharts-title-text,
    .apexcharts-legend-text,
    .block-titles,
    .apexcharts-text,
    .modal-title-my span,
    .el-form-item__label,
    .large--title{
      color: #858585;
        fill: #858585;
    }
    .bgMain,
    .roles .el-tree,
    .modal-cardi-mi .el-card,
    .el-drawer,
    .template-blocks{
      background-color: #ffffff;
    }

    // border table

    .content_header .el-button,
    .el-input__inner,
    .el-drawer__body .el-input__inner,
    .el-textarea__inner
    {
        border: 1px solid #e9e9e9  !important;
        background-color: #ffffff;
        color: #000000;
    }
    .Home-blans{
      border: 1px solid #d9d9d9 !important
    }
    .Suite {
        border: 1px dashed #d7d7d7;
    }
    .right-login-new .el-tabs--border-card{
        border: 1px dashed #2a2f34 !important;
        background-color: #0e2c4685 !important;
        color: #ced4da;
    }
    .right-login-new .el-input__inner {
        background-color: #0e2c4600 !important;
        border: 1px dashed #8d8d8d !important;
        color: #ced4da !important;
    }
    .img-d{
      display: none;
    }
    .img-l{
      display: block;
    }
    .menu-mabil-home li a {
      border-bottom: 1px solid #ebebeb;
      color: red;
    }
}

</style>

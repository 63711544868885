export var profitExpense = {
    series: [
        {
            name: "Оплаченный",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
        {
            name: "Расход",
            data: [6, 33, 21, 34, 42, 35, 55, 65, 105],
        },
    ],
    chartOptions: {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        colors: ['#4CAF50', '#D7263D'],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth'
        },
        markers: {
            size: 5,
        },
        title: {
            text: 'Отчет',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
    },
}
import { register, login, logout, getAuth, getRuleBySlug, passwordReset, saveNewPassword } from "@/api/auth";
import { setToken, removeToken } from "@/utils/auth";


export const actions = {
    getRuleBySlug({ commit }, params) {
        return new Promise((resolve, reject) => {
            getRuleBySlug(params).then(response => {
                commit('SET_ACCOUNT_RULE', response.data.result.data.registerRule)                                                                
                resolve(response.data.result)
            }).catch(error => {  
                reject(error.data)
            })
        })
    },

    register({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            register(credentials).then(response => {
                if (response.data.result.data && response.data.result.data.token) {                    
                    commit('SET_TOKEN', response.data.result.data.token)                                                                
                    setToken(response.data.result.data.token)
                    resolve()
                }
            }).catch(error => {   
                reject(error)
            })
        })
    },

    login({ commit }, credentials) {
        const { phone, password } = credentials;
        return new Promise((resolve, reject) => {
            login({ phone: phone.trim(), password: password }).then(response => {                
                if (response.data.result.data && response.data.result.data.token) {                    
                    commit('SET_TOKEN', response.data.result.data.token)                                                                
                    setToken(response.data.result.data.token)
                    resolve()
                }
            }).catch(error => {                
                reject(error)
            })
        })
    },
    getAuth({ commit, state }, payload) {
        return new Promise((resolve, reject) => {           
            getAuth(state.token).then(res => {    
                const { data } = res.data.result                
                if (!data) {
                    reject('Проверка не удалась, пожалуйста, войдите снова.')
                }
                const { role, name, phone, goodone_admin_slug } = data
                if (!role) {
                    reject('Роль не может быть пустой')
                }
                commit('SET_SLUG', goodone_admin_slug)
                commit('SET_ROLE', role)
                commit('SET_NAME', name)
                commit('SET_PHONE', phone)                
                commit('SET_USER',res.data.result.data)
                commit('SET_SETTINGS',res.data.result.data.settings)
                console.log('success in permissions js')
                resolve(data)
            }).catch(error => {             
                console.log('error in permissions js')
                commit('SET_TOKEN', '');
                removeToken();
                reject(error);
            })
        })
    },
    logout({ commit, state }) {
        return new Promise((resolve, reject) => {
            logout().then(() => {
                removeToken()
                commit('SET_TOKEN', '')
                commit('SET_ROLE', '')
                window.location.reload();
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    resetToken({ commit }) {
        return new Promise((resolve) => {
            removeToken()
            commit('SET_TOKEN', '')
            resolve()
        })
    },
    passwordReset({ commit }, data) {
        return new Promise((resolve, reject) => {
            passwordReset(data).then(response => {    
                resolve(response)
            }).catch(error => {  
                reject(error)
            })
        })
    },
    saveNewPassword({ commit }, data) {
        return new Promise((resolve, reject) => {
            saveNewPassword(data).then(response => {    
                resolve(response)
            }).catch(error => {  
                reject(error)
            })
        })
    },
}

import {i18n} from '@/utils/i18n';

export function notify(res) {  
    try { 
        if ([200,201].includes(res.status)) {
            return this.$notify({
                title: i18n.t('message.successful'),
                type: "success",
                offset: 130,
                message: res.data.result.message
            });
        }
        if (res.status == 422) {
            let message = '';
            let errors = res.data.validation_errors;
            for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                const element = errors[key];
                if (element[0]) {
                message += '<span style="color:red">*</span> ' + element[0] + '<br>'
                }
            }
            }
            return this.$notify({
                title: i18n.t('message.error_m'),
                type: "error",
                offset: 130,
                dangerouslyUseHTMLString: true,
                message: message
            });
        }    
        if(res.status==400){          
            return this.$notify({
                title: i18n.t('message.error_m'),
                type: "error",
                offset: 130,
                message: res.data.error.message 
            });
        }
        if(res.status==403) {    
            return this.$notify({
                title: i18n.t('message.error_m'),
                type: "error",
                offset: 130,
                message: res.data.error.message 
            });
        }
        if(res.status==500) {  
            return this.$notify({
                title: i18n.t('message.error_m'),
                type: "error",
                offset: 130,
                message: res.data.message 
            });
        }
        return this.$notify({
            title: i18n.t('message.error_m'),
            type: "error",
            offset: 130,
            message: i18n.t('message.There is a problem on the server side')
        });
    } catch (error) {    
        alert(error)
    }
}

export function error__message(res) {  
    try { 
        if (!res.data.result.success) {
            return this.$notify({
            title: i18n.t('message.error_m'),
            type: "error",
            offset: 130,
            message: res.data.result.message
            });
        }
    } catch (error) {    
        alert(error)
    }
}

export function parseValidationErrorToStr(messages) {
    return messages.join(', ')
}

export function objectToQuery(obj) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export function permission(permission) {
    return store.getters.role.permissions.some(perm => perm.slug == permission)
}

export function openOffice( name = null, token = null, status = null, formData = null ) {
    if ( name && token && status  )
        var link = document.createElement('a');
        link.setAttribute('href',
            window.location.origin + 
            '/file_orientation' + 
            '?fileName=' + name + 
            '&user=' + token + 
            '&status=' + status + 
            '&formData=' + formData 
        );
        link.setAttribute('target','_blank');
        link.click();
}

export function openOfficeForTemplate( name = null, token = null, status = null, types = null, templateId = null ) {
    if ( name && token && status  )
        var link = document.createElement('a');
        link.setAttribute('href',
            window.location.origin + 
            '/template_orientation' + 
            '?fileName=' + name + 
            '&user=' + token + 
            '&status=' + status + 
            '&templateId=' + templateId +
            '&types=' + types 
        );
        link.setAttribute('target','_blank');
        link.click();
}

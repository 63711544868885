import { model } from "./properties/model";


export const mutations = {
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, client) => {
        if(client.addresses.length === 0){
            client.addresses = state.model.addresses;
        }
        state.model = client;
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
    },
};

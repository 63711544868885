import {index, show, getTypes, createCabinetClient, updateProfile, destroy, createClientAddress, deleteClientAddress} from "@/api/clients";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.clients);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.client);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getTypes({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getTypes(params).then(res => {
                commit("SET_TYPES", res.data.result.data.types);
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    },

    clientDeals({ commit }, id) {
        return new Promise((resolve, reject) => {
            clientDeals(id)
                .then(res => {
                    commit('SET_DEALS', res.data.result.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    createCabinetClient({ commit }, data) {
        return new Promise((resolve, reject) => {
            createCabinetClient(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    createClientAddress({ commit }, data) {
        return new Promise((resolve, reject) => {
            createClientAddress(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteClientAddress({ commit }, id) {
        return new Promise((resolve, reject) => {
            deleteClientAddress(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    // getClientAddresses({ commit }, params) {
    //     return new Promise((resolve, reject) => {
    //         getClientAddresses(params)
    //             .then(res => {
    //                 commit('SET_CLIENT_ADDRESSES', res.data.result.data);
    //                 resolve(res);
    //             })
    //             .catch(err => {
    //                 reject(err);
    //             });
    //     });
    // },

    updateProfile({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateProfile(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};

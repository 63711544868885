export const model = {
    id: null,
    name: '',
    phone: '',
    has_iin: false,
    passport_iin: '',
    passport_number: '',
    passport_series: '',
    passport_given_date: '',
    passport_given_by: '',
    country_id: '',
    city_id: '',
    addresses: [
        {
            id: "",
            client_id: "",
            country_id: "",
            region_id: "",
            city_id: "",
            street: "",
            house: "",
            flat: "",
            zip_code: "",
            landmark: "",
            can_update: true,
            is_default: true,
        }
    ],
};

<template>
    <div class="testi">
        <PrHeader  />
        <div  class="app__row pr__outside" >
            <div :style="{ '--bgImage': `url('${background.url}')` }" :class="[mode ? 'right__sideday' : 'right__sidenight']" class="test__back">
                <section class="app-main">
                    
                    <div class="content-full">
                        <div class="bgMain mb-4 mm_news_on_top p-2">
                            <div class="text-center">
                                Мы запустили новый личный кабинет. Для перехода на новую версию нажмите создать заказ.
                            </div>
                        </div>
                        <router-view> </router-view>
                    </div>
                </section>  
            </div>
        </div>

        <div class="builder">
            <a @click="drawerBuilderBack = true" class="builder__item">
                <i class="el-icon-s-operation"></i>
                <span>BUILDER</span>
            </a>
        </div>
        <el-drawer class="mobil-100" :with-header="false" :visible.sync="drawerBuilderBack" size="28%" ref="drawerBuilderBack" @closed="drawerClosed('drawerBuilderBackChild')" @opened="drawerOpened('drawerBuilderBackChild')">
            <Builder drawer="drawerBuilderBack" ref="drawerBuilderBackChild" />
        </el-drawer>
    </div>
</template>

<script>
import PrHeader from './components/PrHeader.vue'
import Builder from '../components/prcomponents/Builder.vue'
import { mapGetters, mapActions} from "vuex";
export default {
    components: {
        PrHeader,
        Builder
    },
    data() {
        return {
            centerDialogVisible: false,
            drawerBuilderBack: false,
            showingData: [],
            referral_link: '',
        }
    },
    created() {
        this.checkNotifications();
    },
    mounted() {
        let chatScript = document.createElement('script')
        chatScript.setAttribute('src', '//code.tidio.co/ukjbmv9qs0fv18xahx3f6swuypndd1um.js')
        document.head.appendChild(chatScript)
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            leftVersion: "LEFTVERSION",
            openVersion: "OPENVERSION",
            background: "BACKGROUND",
            authUser: "auth/user",
        })
    },
    methods: {
        ...mapActions({
        }),
        closeDrawer(drawer) {
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                this.fetchData();
                this.afterFetchData();
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        async copyReferralLink() {
			try {
				this.referral_link = window.location.origin + '/login/' + this.authUser.referral_secret
				await navigator.clipboard.writeText(this.referral_link);

				this.$message({
					message: this.$t('message.linkCopied'),
					type: 'success'
				});
			} catch($e) {
				console.error('Link Cannot copy', $e);
			}
		},
        checkNotifications() {
        },
    }
};
</script>
<style>
.el-popover {
    position: absolute;
    left: 50% !important;
    transform: translate(-50%);
    z-index: 2009;
    width: 80%;
}

.app-main {
  /* width: calc(100% - 40px); */
  width: 100%;
  min-height: calc(100vh - 200px);
  position: relative;   
  overflow-y: hidden;
  overflow-x: hidden;
  /* margin: 1rem 1.2rem; */
}
/* .el-checkbox.is-bordered {
    height: 32px !important;
    padding: 6px 20px 9px 10px !important;
} */
.el-input--medium .el-input__inner {
    height: 32px !important;
}
.app__row {
    margin: 0 !important;
    width: inherit;
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    max-height: 100%;
}

.pr__outside {
    padding-top: 57px;
}
.right__sidenight .el-loading-mask {
    background-color: rgb(28 28 28 / 90%) !important;
}

.click-pointer {
    cursor: pointer;
}

.builder {
    position: fixed;
    right: 0;
    margin-right: -40px;
    bottom: 50%;
    top: 50%;
    z-index: 99;
}
.builder__item {
    display: flex;
    flex-direction: row;
    transform: rotate(90deg);
    color: #fff;
    background-color: rgba(0, 0, 0, 0.33);
    border-color: transparent;
    padding: 2px 10px;
    cursor: pointer;
    border-radius: 0 0 0.45rem 0.45rem;
}
.builder__item:hover {
    color: #fff;
}
.builder__item i {
    transform: rotate(-90deg);
    font-size: 14px;
}
.builder__item span {
    font-size: 14px;
}
.right__sideday {
  background: #f2f2f2;
  width: 100%;
  height: 100%;
}
.right__sidenight {
  background: #151521;
  width: 100%;
  height: 100%;
  /* padding-left: 78px; */
}
/* .test__back {
    background-size: cover !important;
    background-position: center top !important;
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;
    overflow-x: auto;
    width: 100%;
    height: inherit !important;
} */
.test__back {
    position: relative;
}
.test__back::before {
    background: var(--bgImage) no-repeat center center;
    background-size: cover;
    content: ' ';
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}
.mm_news_on_top {
    border-radius: 5px;
    align-items: center;
    text-align: center;
    color: red;
    font-weight: 700;
    font-size: 16px;
    position: relative;
    overflow: hidden;
}

</style>

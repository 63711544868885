export const model = {
  id: null,
  user_id:  null,
  from_filial_id:  null,
  to_filial_id:  null,
  direction_tariff_id:  null,
  deal_stage_id:  null,
  notification_type:  '',
  shipping_service:  false,
  shipping_address:  '',
  phone: '',
  phone_country_code: 'UZ',
  email: '',
  begin_date: '', 
  end_date: '',
  finish_date: '',
  client_id:  null,
  reason_id:  null,
  client_deal_address_id:  null,
  client_deal_address:  '',
  to_client_deal_address_id:  null,
  to_client_deal_address:  '',
  comment: '',
  deal_type_id: 1, 
  to_client_id: null, 
  to_client_phone: '', 
  to_client_phone_country_code: 'UZ', 
  to_client_email: '', 
  partner_client_id: null,
  partner_phone: '',
  partner_phone_country_code: 'UZ', 
  partner_email: '', 
  payer_client_id: null,
  packages:  [],   
  products:  [],
  delivery_price: 0,   
  parcel:  {
      id: 0,
      price: 0,
      weight: 0,
      width: 0,
      height: 0,
      length: 0,
      tracking_code: '',
      parcel_products: [
        {
          id: '',
          name: '',
          quantity: 1,
          product_price: 0,
          tracking_code: '',
        }
      ],
  },
  services: [],   
  cargo_type:  'parcel', 
  minimum_weight: 0,  
  created_at: '',
  updated_at: '',  
};
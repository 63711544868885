import request from './../utils/request'

export function getInventoryItems(params) {
    return request({
        url: `/${params.action_key}/inventory`,
        method: 'get',
        params
    })
}

export function client_status(params) {
    return request({
        url: '/clients/get/types',
        method: 'get',
        params
    })
}

export function clientAddresses(id) {
    return request({
        url: `/clients/getAccountClientAddresses/${id}`,
        method: 'get',
    })
}

export function senderFilials(params) {
    return request({
        url: '/filials/senderFilials',
        method: 'get',
        params
    })
}
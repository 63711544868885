<template>
    <div>
        <div class="text-center font-weight-bold mb-2">{{ $t('message.your_living_address') }}</div>
        <el-form ref="form" :model="form" label-position="top">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item 
                :label="$t('message.country')" 
                :prop="'country_id'"
                :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.country')}), trigger: 'change' }">
                  <inventory-select
                    :placeholder="$t('message.country')"
                    :id="form.country_id"
                    :action_key="'countries'"
                    v-model="form.country_id"
                    
                    >
                  </inventory-select>
              </el-form-item>
            </el-col>
            <!-- end  el-col -->

            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item :label="$t('message.region')"
                :prop="'region_id'"
                :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.region')}), trigger: 'change' }"
              >
                <inventory-select
                  :placeholder="$t('message.region')"
                  :id="form.region_id"
                  :action_key="'regions'"
                  v-model="form.region_id"
                  :required="['country_id']"
                  :query="{country_id: form.country_id, city_id: form.city_id}"
                  >
                </inventory-select>
              </el-form-item>
            </el-col>
            <!-- end  el-col -->

            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item :label="$t('message.city')"
                :prop="'city_id'"
                :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.city')}), trigger: 'change' }"
              >
                <inventory-select
                  :placeholder="$t('message.city')"
                  :id="form.city_id"
                  :action_key="'cities'"
                  v-model="form.city_id"
                  :required="['country_id']"
                  :query="{country_id: form.country_id, region_id: form.region_id}"
                  >
                </inventory-select>
              </el-form-item>
            </el-col>
            <!-- end  el-col -->

            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item :label="$t('message.street')"
                :prop="'street'"
                :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.street')}), trigger: 'change' }"
              >
                <el-input v-model="form.street" :placeholder="$t('message.street')"></el-input>
              </el-form-item>
            </el-col>
            <!-- end  el-col -->

            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item :label="$t('message.house')" 
                :prop="'house'"
                :rules="{ required: true, message: $t('message.please_select_input', {input: $t('message.house')}), trigger: 'change' }"
              >
                <el-input v-model="form.house"  :placeholder="$t('message.house')"></el-input>
              </el-form-item>
            </el-col>
            <!-- end  el-col -->

            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item :label="$t('message.flat')">
                <el-input v-model="form.flat"  :placeholder="$t('message.flat')"></el-input>
              </el-form-item>
            </el-col>
            <!-- end  el-col -->

            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item :label="$t('message.landmark')">
                <el-input v-model="form.landmark"  :placeholder="$t('message.landmark')"></el-input>
              </el-form-item>
            </el-col>
            <!-- end  el-col -->

            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
              <el-form-item :label="$t('message.zip_code')">
                <el-input v-model="form.zip_code"  :placeholder="$t('message.zip_code')"></el-input>
              </el-form-item>
            </el-col>
            <!-- end  el-col -->
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="danger" @click="close()" round> {{ $t('message.cancel') }} </el-button>
            <el-button type="success" @click="save()" round> {{ $t('message.save') }} </el-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import inventorySelect from "@/includes/selects/inventory-select";

export default {
    name: 'ClientCabinetHavvoexCreateRecipient',
    props: {
      client: {
        default: Object
      }
    },
    components: {
      inventorySelect,
    },
    data() {
      return {
        loading: false,
        form: {
          id: "",
          client_id: "",
          country_id: "",
          region_id: "",
          city_id: "",
          street: "",
          house: "",
          flat: "",
          zip_code: "",
          landmark: "",
          is_default: false,
        }, 
      };
    },

    mounted() {
        
    },
    computed: {
      ...mapGetters({
        authUser: "auth/user",
      }),
    },

    methods: {
      ...mapActions({
        createClientAddress: 'clients/createClientAddress',
      }),
      close(){
        this.$parent.$parent.createAddress = false;
      },
      save(){
        this.form.client_id = this.client.id
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.loading = true;
            this.createClientAddress(this.form)
              .then(res => {
                this.close();
                this.$alert(res);
              }).catch(err => console.log(err, 'error create address error'))
          }
        });
      },

    },
};
</script>

<style lang="scss" scoped>

</style>
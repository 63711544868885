<template>
  <div>
    <header id="el-drawer__title" class="el-drawer__header">
      <span title="Новый заказ" class="colorMain">
        {{ $t('message.deal') + ' No ' + selectedItem.id  }}
      </span>
      <el-button @click="close()" type="danger" icon="el-icon-close" size="small">{{ $t('message.close') }}</el-button>
    </header>
      <div class="cardMain bgMain" v-loading="loadingData">
        <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="Suite">
                <h4 class="colorMain"> {{ $t('message.direction') }} {{ model.directionTariff ?  model.directionTariff.name : ''}}</h4>
                <ul class="colorMain">
                  <li><i class="el-icon-user"></i> {{ model.reciever ? model.reciever.name : '' }}</li>
                  <li class="color-red"> <i class="el-icon-aim"></i> {{ model.reciever ? model.reciever.custom_id : '' }}</li>
                  <li><i class="el-icon-phone"></i> {{ (model.deal_type_id == 1) ? model.phone : model.to_client_phone }}</li>
                  <li><i class="el-icon-location-outline"></i> {{ (model.deal_type_id == 1) ? model.client_deal_address : model.to_client_deal_address }}</li>
                </ul>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="Suite">
                <h4 class="colorMain"> {{ $t('message.deal_info') }}</h4>
                <ul class="colorMain">
                  <li><i class="el-icon-search"></i> Трекинг код: {{ model.tracking_code }}</li>
                  <li><i class="el-icon-box"></i> Вес: {{ model.all_weight | formatNumber(1) }} {{ $t('message.kg') }} </li>
                  <li><i class="el-icon-price-tag"></i> Стоимость груза: {{ model.products_total_sum  | formatNumber(1) }} $ </li>
                  <li><i class="el-icon-ship"></i> Стоимость доставки: {{ dealTotal | formatNumber(1)}} $ </li>
                </ul>
              </div>
            </el-col>
        </el-row>

        <el-button @click="downloadDealInfoPDF(model)" type="primary" icon="el-icon-s-claim" round>{{ $t('message.invoice') }}</el-button>
        <el-button v-if="!isDealPaid(model) && isClientPayer(model) && dealPlacementCheck" @click="payOut(model)" type="success" icon="el-icon-bank-card" round>{{ $t('message.payment_for_deal') }}</el-button>
        <h4 class="colorMain"> {{ $t('message.products') }}</h4>
        <div class="scrol-table">
          <table class="table table-bordered table-hover" >
            <thead>
              <tr>
                <th>№</th>
                <th>{{ $t('message.category') }}</th>
                <th>{{ $t('message.comment') }}</th>
                <th>{{ $t('message.qty_y') }}</th>
                <th>{{ $t('message.weight') }}</th>
                <th>{{ $t('message.price') }} $</th>
                <th>{{ $t('message.tracking_code') }}</th>
                <th>{{ $t('message.status') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(prod, index) in prodLocationsWithStatus" :key="index">
                <td>{{ index +1 }}</td>
                <td>{{ prod.name }}</td>
                <td>{{ prod.comment }}</td>
                <td>{{ prod.quantity }}</td>
                <td>{{ prod.weight }}</td>
                <td>{{ prod.product_price | formatNumber(1) }} $</td>
                <td>{{ prod.tracking_code }}</td>
                <td><div class="status-color">{{ $t('message.'+ prod.status) }}</div></td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4 class="colorMain mt-5"> {{ $t('message.services') }}</h4>
        <div class="scrol-table">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>№</th>
                <th>{{ $t('message.name') }}</th>
                <th>{{ $t('message.price') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(service, index) in model.services" :key="index">
                <td>{{ index +1 }}</td>
                <td>{{ service.service ? service.service.name : service.name }}</td>
                <td>{{ (service.service_sum ? service.service_sum : ((service.percent * model.products_total_sum) / 100)) | formatNumber(1) }} $ </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  </div>
</template>

<script>
import drawer from "@/utils/mixins/drawer";
import show from "@/utils/mixins/show";
import { mapGetters, mapActions } from "vuex";
import stripe_payment from "@/utils/mixins/payment/stripe_payment";

export default {
    name: 'ClientCabinetHavvoexOldOrder',
    mixins: [drawer, show, stripe_payment],
    props: {
      deal_status: {
        default: 'new_deals'
      }
    },
    data() {
        return {
          loadingProdLocations: false,
          prodLocationsWithStatus: []
        };
    },

    computed: {
      ...mapGetters({
        model: "deals/model",
        dealProductsLocation: "dealProducts/dealProducts",
      }),
      dealTotal(){
        let total = this.model.product_total + this.model.total_services_price;
        if(this.model.cargo_type != 'parcel' && this.model.shipping_service){
          total += (parseFloat(this.model.all_weight) * parseFloat(this.model.delivery_price));
        }   
        return _.round(total, 2);
      },
      dealPlacementCheck(){
        return !['new_deals', 'active_deals'].includes(this.deal_status);
      }
    },

    methods: {
      ...mapActions({
        show: "deals/show",
        downloadDealInfo: "deals/downloadDealInfo",
        showProdLocations: "dealProducts/show",
      }),
      fetchData() {         
        if (!this.loadingData) {
          this.loadingData = true
          this.show(this.selectedItem.id).then(res => {
              this.loadingData = false
          }).catch(err => {
              this.loadingData = false
          });
          this.updateProdLocation();
        }
      },
      updateProdLocation(){
        this.loadingProdLocations = true;
        this.showProdLocations({ id: this.selectedItem.id })
          .then(async (res) => {
            await this.updateList();
            this.loadingProdLocations = false;
          })
          .catch((err) => {
            this.loadingProdLocations = false;
          });
      },
      updateList(){
        let prod_locations = ['from_filial_remainder', 'packing', 'on_flight', 'unpacking'];
        this.dealProductsLocation.forEach(prod => {
          let statuses = {
            waiting: 0,
            on_way: 0,
            ready_to_send_client: 0,
            on_client: 0
          };
          prod_locations.forEach(loc => {
            statuses.on_way += (prod[loc] && prod[loc] != '-' && prod[loc] > 0) ? parseFloat(prod[loc]) : 0;
          });
          statuses.on_client = (prod.client_remainder && prod.client_remainder != '-') ? parseFloat(prod.client_remainder) : 0;
          statuses.ready_to_send_client = (prod.to_filial_remainder && prod.to_filial_remainder != '-') ? parseFloat(prod.to_filial_remainder) : 0;

          statuses.waiting = parseFloat(prod.quantity) - parseFloat(statuses.on_way + statuses.on_client + statuses.ready_to_send_client);

          Object.keys(statuses).forEach(key => {
            if(statuses[key] > 0){
              if(prod.parcel_products && prod.parcel_products.length > 0){
                prod.parcel_products.forEach(parcel_prod => {
                  this.prodLocationsWithStatus.push({
                    name:           parcel_prod.name,
                    comment:        '',
                    product_price:  parcel_prod.product_price,
                    tracking_code:  prod.tracking_code,
                    quantity:       parcel_prod.quantity,
                    weight:         parcel_prod.weight,
                    status:         key,
                  });
                });
              }else{
                this.prodLocationsWithStatus.push({
                  name:           prod.product ? prod.product.name : '',
                  comment:        prod.comment,
                  weight:         prod.weight,
                  product_price:  prod.product_price,
                  tracking_code:  prod.tracking_code,
                  quantity:       statuses[key],
                  status:         key,
                })
              }
            }
          });

          
        });
      },
      downloadDealInfoPDF(deal){  
        this.downloadDealInfo({deal_id: deal.id, cabinet: true})
          .then((res) => {
            const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
            WinPrint.document.write(res.data);
            WinPrint.document.close();
            WinPrint.focus();
            setTimeout(() => {
                WinPrint.print();
                WinPrint.close();
            }, 1000);
          }).catch((err) => {
            this.$alert(err);
          });
      },

      afterLeave(){
        this.prodLocationsWithStatus = [];
      }
      
    },
};
</script>

<style lang="scss" scoped>

</style>
export const mutations = {
    SET_TOKEN: (state, token) => state.token = token,
    SET_ROLE: (state, role) => state.role = role,
    SET_NAME: (state, name) => state.name = name,
    SET_PHONE: (state, phone) => state.phone = phone,
    SET_EMAIL: (state, email) => state.email = email,
    SET_SLUG: (state, slug) => state.slug = slug,
    SET_CLIENT_STATUS:(state,status)=>state.status=status,
    SET_USER:(state, user) => {
        state.user = {
            id: user.id,
            name: user.name,
            phone: user.phone,
            email: user.email,
            status: user.status,
            is_employee: user.is_employee,
            is_partner: user.is_partner,
            client_id: user.client_id,
            client: user.client,
            address_count: user.address_count,
            user_bonus_total: user.user_bonus_total,
            referral_secret: user.referral_secret,
            referral_users_count: user.referral_users_count,
        }
    },
    SET_SETTINGS:(state,settings)=>state.settings=settings,
    SET_ACCOUNT_RULE:(state, account_rule)=> state.account_rule = account_rule,
};

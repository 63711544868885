import { getColumnSetting, updateColumnSetting, deleteAppColumnSetting } from '@/api/appColumnSettings';

export const actions = {
    getColumnSetting({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getColumnSetting(params).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateColumnSetting({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateColumnSetting(data).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    deleteAppColumnSetting({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            deleteAppColumnSetting(params).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
};

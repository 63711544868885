import { show, destroy, deleteParcelProduct } from "@/api/dealProducts";

export const actions = {

    show({ commit }, params) {
        return new Promise((resolve, reject) => {
            show(params).then(res => {
                commit("SET_DEALPRODUCTS", res.data.result.data.dealProducts);
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    deleteParcelProduct({ commit }, id) {
        return new Promise((resolve, reject) => {
            deleteParcelProduct(id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },


};

import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],
    phone: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.phone')}), trigger: 'change' },
    ],
    // passport_number: [
    //     { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.passport_number')}), trigger: 'change' },
    // ],
    // passport_series: [
    //     { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.passport_series')}), trigger: 'change' },
    // ],
    country_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.country')}), trigger: 'change' },
    ],
    city_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.city')}), trigger: 'change' },
    ],
};
export var total_length_piechart = {
    series: [ 50, 70],
    chartOptions: {
        chart: {
            type: 'donut',
        },
        labels: ['Pullik masofa ', 'Umumiy masofa '],
        responsive: [{
            breakpoint: 480,
            options: {
            chart: {
                width: 200
            },
            legend: {
                position: 'bottom'
            }
            }
        }]
    },
}
import { columns } from './properties/columns'
import { rules } from "./properties/rules";
import { model } from "./properties/model";

export const state = {
    list: [],
    inventory: [],
    model: JSON.parse(JSON.stringify(model)),
    columns: columns,
    rules: rules,
};
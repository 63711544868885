import request from '@/utils/request'

export function getProfitExpense(params) {
    return request({
        url: '/charts/getProfitExpense',
        method: 'get',
        params: params
    })
}

export function bestDrivers(params) {
    return request({
        url: '/charts/bestDrivers',
        method: 'get',
        params: params
    })
}

export function averageLength(params) {
    return request({
        url: '/charts/averageLength',
        method: 'get',
        params: params
    })
}

export function selectedTariff(params) {
    return request({
        url: '/charts/selectedTariff',
        method: 'get',
        params: params
    })
}

export function totalLength(params) {
    return request({
        url: '/charts/totalLength',
        method: 'get',
        params: params
    })
}

export function activeClients(params) {
    return request({
        url: '/charts/activeClients',
        method: 'get',
        params: params
    })
}




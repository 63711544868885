<template>
  <div class="style-home ">
    <filter-table-mobile ref="filter_table" @updateActiveFilter="updateActiveFilter"></filter-table-mobile>
    <el-row :gutter="20">
      <el-col v-loading="loading"  :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <div class="Suite bgMain minH-310">
            <h4 class="colorMain title-ee">
              {{ authUser.name }}
            </h4>
            <ul class="colorMain">
                <li><i class="el-icon-location-outline"></i>{{authUser.client.country ? authUser.client.country.name : ''}}</li>
                <li class="color-red"><i class="el-icon-aim"></i>{{authUser.client ? authUser.client.custom_id : ''}}</li>
                <li><i class="el-icon-phone"></i> {{authUser.phone}}</li>
                <li><i class="el-icon-postcard"></i>
                  {{  authUser.client.has_iin ? authUser.client.passport_iin : ((authUser.client.passport_series ? authUser.client.passport_series : '') + ' ' + (authUser.client.passport_number ? authUser.client.passport_number : '')) }}
                </li>
            </ul>
          </div>
      </el-col>
      <!-- end  el-col -->

      <el-col v-loading="loading" :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
        <!-- <div class="orderHomeBig"> -->
          <!-- <div class="minH-310"> -->
            <el-row :gutter="10">
              <el-col  v-for="filial in filteredFilials" :key="filial.id" :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                <div class="Suite list-heghti orderHome bgMain cardMain minH-310">
                  <h4 class="colorMain title-ee">
                    {{ filial.country_name }}
                  </h4>
                  <ul class="colorMain">
                    <li><i class="el-icon-aim"></i>  {{ filial.name }}</li>
                    <li><i class="el-icon-phone"></i> {{ filial.phone_1 }}   <span :class="filial.phone_1 ? 'ml-3' : ''">{{ filial.phone_2 }}</span></li>
                    <li class="color-red"><i class="el-icon-position"></i> {{ filial.country_name }}  {{ filial.city_name }} </li>
                    <li><i class="el-icon-location-outline"></i>  {{ filial.address }}</li>
                  </ul>
                </div>
              </el-col>
            </el-row>
          <!-- </div> -->
          <router-link v-if="filials.length > 3" class="show-more" to="companyAddresses">
              {{ $t('message.show_more') }}
          </router-link>
        <!-- </div> -->
      </el-col>
    </el-row>
    <!-- end  el-row -->
    <div class="cardMain bgMain mt-30 mobil-none-block">
      <el-row :gutter="10" style="margin-bottom:20px">
        <filter-table ref="filter_table" @updateActiveFilter="updateActiveFilter"></filter-table>
        <el-col :xs="24" :sm="6" :md="6" :lg="4" :xl="2">
          <a href="https://me.havvoex.com/auth?redirect=/" target="_blank">
            <el-button class="w-100 mb-10 action-btn" round><i class="el-icon-plus"></i> Заказ</el-button>
          </a>
          <!-- <router-link to="order">
            <el-button class="w-100 mb-10 action-btn" round><i class="el-icon-plus"></i> Заказ</el-button>
          </router-link> -->
        </el-col>
      </el-row>
      <div v-if="windowWidth > mobileWidth" class="scrol-table">
        <table v-loading="loadingData" class="table table-bordered table-hover" >
            <thead>
              <tr>
                <th v-if="columns.id.show">
                  {{ columns.id.title }}
                </th>

                <th v-if="columns.direction_tariff.show">
                  {{ columns.direction_tariff.title }}
                </th>

                <th v-if="columns.tracking_code.show">
                  {{ columns.tracking_code.title }}
                </th>

                <th v-if="columns.reciever.show">
                  {{ columns.reciever.title }}
                </th>

                <th v-if="columns.reciever_address.show">
                  {{ columns.reciever_address.title }}
                </th>

                <th v-if="columns.all_weight.show">
                  {{ columns.all_weight.title }}
                </th>

                <th v-if="columns.created_at.show">
                  {{ columns.created_at.title }}
                </th>
                <th>{{ $t('message.more') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="deal in list" :key="deal.id" class="cursor-pointer">
                <td v-if="columns.id.show">
                  <div style="font-weight: 700;">
                      <i v-if="deal.cargo_type === 'parcel'" class="el-icon-message" style="color: #0017ff;"></i>
                      <i v-else class="el-icon-box" style="color: #7f6918;"></i>
                      {{ deal.id }}
                  </div>
                </td>
                <td v-if="columns.direction_tariff.show">
                    {{deal.directionTariff ? deal.directionTariff.name : '' }}
                </td>
                <td v-if="columns.tracking_code.show">
                    {{ deal.tracking_code }}
                </td>
                <td v-if="columns.reciever.show">
                  <span v-if="deal.reciever">
                      {{ deal.reciever ? (deal.reciever.custom_id ? ('ID: ' + deal.reciever.custom_id) : '') : '' }} <br>
                      {{ deal.reciever ? deal.reciever.name : '' }}<br>
                      {{ deal.reciever ? deal.to_client_phone : '' }}
                  </span>
                </td>
                <td v-if="columns.reciever_address.show">
                    {{ (deal.deal_type_id === 1) ? deal.client_deal_address : deal.to_client_deal_address }}
                </td>
                <td v-if="columns.all_weight.show">
                    {{ deal.all_weight | formatNumber(1)}} kg
                    /
                    {{ (deal.all_weight * $kg_to_pound) | formatNumber(1)}} lbs
                </td>
                <td v-if="columns.created_at.show">
                    {{ deal.created_at }}
                </td>
                <td>
                  <div class="status-color2 mm_font_size_20">
                    <i class="el-icon-view" @click="showSelected(deal)"></i>
                    <i v-if="!isDealPaid(deal) && isClientPayer(deal) && dealPlacementCheck" class="el-icon-bank-card" @click="payOut(deal)"></i> 
                  </div>
                </td>
              </tr>
            </tbody>
        </table>
      </div>
    </div>
    <form ref="payment">
      <stripe-checkout
        ref="checkoutRef"
        :pk="public_key"
        :session-id="sessionId"
      />
    </form>

    <el-drawer
      size="100%"
      title="I am the title"
      ref="drawerShow"
      @opened="drawerOpened('drawerShowChild')"
      @closed="drawerClosed('drawerShowChild')"
      :visible.sync="drawerShow"
      :with-header="false">
        <old-order
          ref="drawerShowChild"
          drawer="drawerShow"
          :selectedItem="this.selectedItem">
        </old-order>
    </el-drawer>

    <el-dialog class="mobil-100" :title="$t('message.reciever')" width="80%" :visible.sync="createRecipient">
      <create-recipient></create-recipient>
    </el-dialog>

    <el-dialog class="mobil-100"
      :title="$t('message.reciever')"
      ref="drawerUpdate"
      :visible.sync="updateRecipient"
      @opened="drawerOpened('drawerUpdateChild')"
      @closed="drawerClosed('drawerUpdateChild')"
      width="80%">
      <update-recipient ref="drawerUpdateChild" :selectedProfile="selectedItem"></update-recipient>
    </el-dialog>

    <el-dialog class="mobil-100" :title="$t('message.reciever_address')" width="80%" :visible.sync="createAddress">
      <create-address :client="selectedItem"></create-address>
    </el-dialog>

  </div>
</template>

<script>
import OldOrder from '@/components/oldOrder.vue';
import {  mapActions, mapGetters } from 'vuex';
import list from "@/utils/mixins/list";
import filterTableMobile from '@/views/all_deals/components/filter-table-mobile.vue';
import filterTable from '@/views/all_deals/components/filter-table.vue';
import stripe_payment from "@/utils/mixins/payment/stripe_payment";

import createAddress from './../views/recievers/create-address.vue';
import createRecipient from './../views/recievers/create-recipient.vue';
import updateRecipient from './../views/recievers/update-recipient.vue';

export default {
  name: 'Home',
  mixins: [list, stripe_payment],
  
  data() {
    return {
      loading: false,
      createRecipient: false,
      updateRecipient: false,
      createAddress: false,
      mobileWidth: 1100,
      windowWidth: window.innerWidth,
    }
  },
  components: {
    OldOrder,
    filterTable,
    filterTableMobile,
    createAddress,
    createRecipient,
    updateRecipient,
  },
  computed: {
    ...mapGetters({
      mode: "MODE",
      authUser: "auth/user",
      filials: "inventories/filials",
      list: "deals/list",
      filter: "deals/filter",
      columns: "deals/columns",
      pagination: "deals/pagination",
    }),

    filteredFilials(){
      return this.filials.length > 3 ? this.filials.slice(0, 3) : this.filials;
    },
    dealPlacementCheck(){
      return !['new_deals', 'active_deals', 'on_the_way'].includes(this.filterForm.placement);
    }
  },
  created() {
    if(this.filials && this.filials.length === 0){
      this.loading = true;
      this.senderFilials()
        .then(res => {
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        });
    }
    this.filterForm = JSON.parse(JSON.stringify(this.filter));
    this.debouncedFetchData = _.debounce(this.fetchData, 500);
  },

  methods: {
    ...mapActions({
      senderFilials: "inventories/senderFilials",
      updateList: "deals/index",
      setPagination: "deals/setPagination",
      updateSort: "deals/updateSort",
      updateFilter: "deals/updateFilter",
      updateColumn: "deals/updateColumn",
      toggleColumns: "deals/toggleColumns",
      updatePagination: "deals/updatePagination",
      show: "deals/show",
      delete: "deals/destroy",
      updateDealProducts: "dealProducts/show",
      refreshData: "deals/refreshData",
    }),
    updateCurrentRecipiant(client){
      if(client.id === this.authUser.client_id){
        this.$router.push({ path: '/profil' })
      }else{
        this.selectedItem = client;
        this.updateRecipient = true;
      }
    },
    openAddressDialog(client){
      this.selectedItem = client;
      this.createAddress = true;
    },

    updateActiveFilter(placement){
        this.$set(this.filterForm, 'placement', placement)
    },

  }

}
</script>

<style>
.Home-blans i{
    position: absolute;
    right: 22px;
    font-size: 5rem;
}
.Home-blans-title.colorMain {
    font-size: 2rem;
    margin-bottom: 1.2rem;
}
.Home-blans-dollar.colorMain {
    font-size: 2rem;
    color: red !important;
    font-weight: 700;
    margin-bottom: 1.2rem;
}
.btn-mian{
  min-width: 150px;
  border-radius: 5px !important;
}
.Suite{
  border: 1px solid;
  padding: 20px;
  border-left: 3px solid red !important;
  border-radius: 5px;
}
.Suite h4{
  margin: 0px;
  margin-bottom: 15px;
}
.Suite ul li{
    font-size: 15px;
    border-bottom: 1px dashed #959595;
    padding: 7px 0;
}
.Suite ul li:last-child {
  border-bottom: none;
}
.orderHomeBig{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.orderHome{
    /* border: 1px solid #fff; */
    width: 100%;
}
.show-more{
    border: 1px solid;
    color: #fff;
    padding: 10px 20px;
    border-color: #e72434;
    background: #E72434;
    /* display: flex; */
    /* justify-content: center; */
    /* width: 100px; */
    margin-top: -20px;
    position: relative;
    z-index: 99;
    /* border-top: #080c24; */
    border-radius: 50px;
}
.minH-310{
  min-height: 240px;
}
.Home-blans{
    border: 1px solid #080c24 !important;
    display: flex;
    flex-direction: column;
}
.status-color2{
  cursor: pointer;
}
.el-drawer__header{
    padding: 20px 20px;
    border-bottom: 1px solid;
}
</style>

import { profile, createCabinetClient, updateProfile, destroy } from "@/api/clients";

export const actions = {
    profile({ commit }, id) {
        return new Promise((resolve, reject) => {
            profile(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.client);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            createCabinetClient(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateProfile(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },
    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
};
import { getProfitExpense,bestDrivers,averageLength,selectedTariff,totalLength,activeClients } from "@/api/charts";

export const actions = {

    getProfitExpense({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getProfitExpense(params)
                .then(res => {
                    commit("SET_PROFIT_EXPENSE", res.data.result.data.profitExpense);
                    resolve(res.data.result.data.profitExpense);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    bestDrivers({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            bestDrivers(params)
                .then(res => {
                    commit("SET_BEST_DRIVERS", res.data.result.data.best_drivers);
                    resolve(res.data.result.data.best_drivers);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    averageLength({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            averageLength(params)
                .then(res => {
                    commit("SET_AVERAGE_LENGTH", res.data.result.data.average_length);
                    resolve(res.data.result.data.average_length);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    selectedTariff({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            selectedTariff(params)
                .then(res => {
                    commit("SET_SELECTED_TARIFF", res.data.result.data.selected_tariff);
                    resolve(res.data.result.data.selected_tariff);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    totalLength({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            totalLength(params)
                .then(res => {
                    commit("SET_TOTAL_LENGTH", res.data.result.data.total_length);
                    resolve(res.data.result.data.total_length);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    activeClients({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            activeClients(params)
                .then(res => {
                    commit("SET_ACTIVE_CLIENT", res.data.result.data.active_client);
                    resolve(res.data.result.data.active_client);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    

    
};
import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: '№',
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    phone : {
        show: true,
        title: i18n.t('message.phone'),
        sortable: true,
        column: 'phone'
    },
    role_id: {
        show: true,
        title: i18n.t('message.role'),
        sortable: true,
        column: 'role_id'
    },
    status : {
        show: true,
        title: i18n.t('message.status'),
        sortable: true,
        column: 'status'
    },
    first_name : {
        show: false,
        title: i18n.t('message.first_name'),
        sortable: true,
        column: 'first_name'
    },
    surname: {
        show: false,
        title: i18n.t('message.Surname'),
        sortable: true,
        column: 'surname'
    },
    patronymic: {
        show: false,
        title: i18n.t('message.Middle name'),
        sortable: true,
        column: 'patronymic'
    },
    email: {
        show: false,
        title: i18n.t('message.email'),
        sortable: true,
        column: 'email'
    },
    last_login : {
        show: false,
        title: i18n.t('message.Last login'),
        sortable: true,
        column: 'last_login'
    },
    password : {
        show: false,
        title: i18n.t('message.Password'),
        sortable: true,
        column: 'password'
    },
    created_at: {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};

export var piechart = {
    series: [44, 55, 41, 17, 15],
    chartOptions: {
        chart: {
            type: 'donut',
        },
        labels: ['Driver 1', 'Driver 2','Driver 3','Driver 4','Driver 5'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
                width: 200
            },
            legend: {
                position: 'bottom'
            }
          }
        }]
    },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show:true,
                fontSize: '14px',
                fontFamily: 'Arial',
                fontWeight: 300,
                color: 'black',
                offsetY: 500,
                formatter: () => 'text i want'
              },
              value: {
                show:true,
                fontSize: '14px',
                fontFamily: 'Arial',
                fontWeight: 300,
                color: 'black',
                offsetY: 500,
                formatter: function (val) {
                  return  'Сумма: ' + val;
                },
              },
              total: {
                show:true,
                showAlways: true,
                label: 'Total',
                fontSize: '14px',
                fontFamily: 'Arial',
                fontWeight: 300,
                color: 'black',
                formatter: () => 'text i want'
              }
            }
          }
        }
      },
      legend: {
        show: true,
        fontSize: '13px',
        width: 170,
        height: 250,
        labels: {
            colors: '#fff',
            useSeriesColors: false
        },
        markers: {
            strokeColor: '#fff',
        },
      },
    
}
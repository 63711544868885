export const model = {
    id : null,  
    client_id: null, 
    payment_type_id: null, 
    money_amount: 0, 
    remainder: 0, 
    currency_rate: 0, 
    bank_account_id: null, 
    comment: '', 
    paymentsForDeals: [], 
    created_at: '',
    updated_at: ''
};
    
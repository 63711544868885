import { model } from "./properties/model";

export const mutations = {
    SET_MODEL: (state, deal) => { state.model = deal },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));          
    },
    UPDATE_DEAL_PARTNER_PRICES: (state, partnerTariffPrice) => { state.partnerTariffPrice = partnerTariffPrice },
    UPDATE_DEAL_PRODUCT_PRICES: (state, productTariffPriceList) => { state.productTariffPriceList = productTariffPriceList },

};

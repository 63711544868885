<template>
  <div>
    <div class="pr__navbar" :class="mode ? 'top__day' : 'top__night'">
      <div class="topnav__box">
        <div class="pr__flex">
          <router-link tag="div" to="/" class="logo menu__logot cursor-pointer">
            <img class="img-d" src="img/logo.svg" alt="">
            <img class="img-l" src="img/logo2.svg" alt="">
          </router-link>
        </div>

        <div class="haeder-menu d-m-none">
          <router-link class="menu-wrapper-itme" to="/">
            {{ $t('message.dashboard') }}
          </router-link>
          <router-link class="menu-wrapper-itme" to="companyAddresses">
            {{ $t('message.company_addresses') }}
          </router-link>
          <router-link class="menu-wrapper-itme" to="recievers">
            {{ $t('message.recievers') }}
          </router-link>
          <router-link class="menu-wrapper-itme" to="all_deals">
            {{ $t('message.all_deals') }}
          </router-link>
          <router-link class="menu-wrapper-itme" to="balance">
            {{ $t('message.balance') }}
          </router-link>
          <!-- <router-link class="menu-wrapper-itme" to="bonus">
            {{ $t('message.bonus') }}
          </router-link> -->
        </div>

        <div class="topnav__right">
          <div class="topnav__fast">
            <ul>
              <!-- <li class="mm__bonus_amount">
                <span class="font-bold">
                  +{{ authUser.user_bonus_total || 0 }}$
                </span>
              </li> -->
              <li>
                <a href="https://me.havvoex.com/auth?redirect=/" target="_blank">
                  <el-button class="w-100 action-btn" size="small" round> <i class="el-icon-plus"></i> {{ $t('message.order') }} </el-button>
                </a>
                <!-- <router-link to="order">
                  <el-button class="w-100 action-btn" size="small" round> <i class="el-icon-plus"></i> {{ $t('message.order') }} </el-button>
                </router-link> -->
              </li>
              <li class="h-right-link d-m-none">
                <router-link to="profil">
                  <i class="el-icon-user"></i>
                </router-link>
              </li>
              <li class="h-right-link  d-m-none" @click="logout">
                <i class="fa-solid fa-arrow-right-to-bracket"></i>
              </li>

              <li class="h-right-link  d-m-block" @click="table = true">
                  <i class="el-icon-s-operation"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>


    <el-drawer
        class="mobile-menu"

        :visible.sync="table"
        direction="ltr"
        size="90%">
        <div class="left-menu-mobil">
          <router-link tag="div" to="/" class="logo menu__logot cursor-pointer">
              <img class="img-d" src="img/logo.svg" alt="">
              <img class="img-l" src="img/logo2.svg" alt="">
          </router-link>

          <ul>
            <li>
              <a  v-bind:href="'/'"> {{ $t('message.dashboard') }}</a>
            </li>
            <li>
              <a  v-bind:href="'companyAddresses'"> {{ $t('message.company_addresses') }}</a>
            </li>
            <li>
              <a  v-bind:href="'recievers'"> {{ $t('message.recievers') }}</a>
            </li>
            <li>
              <a  v-bind:href="'all_deals'">{{ $t('message.all_deals') }}</a>
            </li>
            <li>
              <a  v-bind:href="'balance'">{{ $t('message.payments') }}</a>
            </li>

            <!-- <li>
              <a  v-bind:href="'balance'">{{ $t('message.bonus') }}</a>
            </li> -->

            <li>
              <a  v-bind:href="'profil'">{{ $t('message.profile') }}</a>
            </li>

            <li>
              <a @click="logout">{{ $t('message.logout') }}</a>
            </li>
          </ul>
        </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'topNav',
  data: () => ({
    popup: false,
    table: false,
    drawerMiniSettings: false,
    settings: false,
    showSetLockPasswordModal: false,
    password: '1234',
    repeat_password: '',
    profileInfo: false,
    staffOnOff: false,
    system_language: localStorage.getItem('sys_language') ? localStorage.getItem('sys_language') : 'ru'
  }),
  watch: {
    $route (to, from) {
      this.getTest()
    }
  },
  mounted () {
    this.getTest()
  },
  computed: {
    ...mapGetters({
      authUser: 'auth/user',
      mode: 'MODE',
      leftVersion: 'LEFTVERSION',
      links: 'LINKS'
    }),
  },
  methods: {
    ...mapActions({
      userLogOut: 'auth/logout'
    }),
    lockUser () {
      // set lock password if it's not set
      if (this.lockPassword === '') {
        this.showSetLockPasswordModal = true
      } else {
        this.TRIGGER_LOCK({ status: true })
      }
    },
    setLanguage (val) {
      localStorage.setItem('sys_language', val)
      this.$i18n.locale = val
    },
    fullMode (e) {
      const full = document.documentElement
      this.fullSize = true
      if (full.requestFullscreen) {
        full.requestFullscreen()
      } else if (full.msRequestFullscreen) {
        full.msRequestFullscreen()
      } else if (full.mozRequestFullscreen) {
        full.mozRequestFullscreen()
      } else if (full.webkitRequestFullscreen) {
        full.webkitRequestFullscreen()
      }
    },
    menuToggle () {
      this.$store.commit('toggleMenu')
    },
    getTest () {
      this.name = this.$route.name.substring(0, this.$route.name.length - 6) + '/total_price'
    },
    onClickOutside () {
      this.settings = false
    },
    clickInside () {
      this.settings = !this.settings
      this.profileInfo = false
    },
    logout () {
      this.$confirm(
        'Вы уверены, что вы хотите выйти из системы?',
        'Выход из системы',
        {
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмен',
          type: 'warning',
          center: true
        }
      )
        .then(() => {
          this.userLogOut()
            .then(() => {})
            .catch((error) => {
              window.location.reload()
              reject(error)
            })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: 'Операция отменена'
          })
        })
    }
  }
}
</script>
<style scoped lang="scss">
.pr__navbar {
    z-index: 1002;
    background: #fff;
    position: fixed;
    width: 100%;
    top: 0;
}
.settings__outside {
    position: relative;
}
.settings__inside {
    display: flex;
    position: absolute;
    width: 180px;
    right: 0;
    border-radius: 10px;
    margin-left: -140px;
    margin-top: 50px;
}
.settings__insideday {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background: #fff;
}
.settings__insidenight {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background: #1E1E2D;
}
.settings__item a {
    background: #fff ;
}
.settings__ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px 0 !important;
}
.unique__navbar {
    position: fixed;
    right: 0;
    margin-right: 20px;
}
.settings__item {
    display: flex;
    widows: 100%;
    margin-left: 0 !important;
    cursor: pointer;
    padding: 3px 0px 3px 20px !important;

}
.settings__item:hover {
    background-color: rgba(34, 34, 34, 0.091);
}
.settings__item {
    font-size: 13px;
    font-weight: 400;
}
.settings__item a:hover {
    background: #fff !important;
}
.pr__flex {
    display: flex;
}
.logo {
    margin-left: 0px;
    margin-right: 55px;
    margin-bottom: 0 !important;
}
.logo img {
    width: auto !important;
    height: 2rem;
}
.menu__toggle {
  display: flex;
  align-items: center;
}
.menu__toggle {
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 5px;
    margin-left: -10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: opacity .1s ease-out;
}
.menu__toggle {
    margin-right: 30px;
}
.menu__toggle:active {
    background-color: rgba(0, -1, -2, 0.05);
    border: 1px solid #e8e5dd;
    border-radius: 50%;
}
.topnav__box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
}
.position__relative {
  position: relative;
}

.topnav__icon svg rect {
  fill: #92929f;
}
.topnav__input {
  padding: 0.50rem 0.5rem;
  outline: none;
  background: inherit;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
}
.topnav__inputday {
  padding: 0.50rem 0.5rem;
  outline: none;
  background: inherit;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #323248;
}
.topnav__icon svg {
  margin-top: 3px;
}
.topnav__right {
  display: flex;
  align-items: center;
}
.menu__logot {
  display: flex;
  align-items: center;
}
.topnav__nameinfo {
  margin-left: 20px;
}
.topnav__breadcrumb {
  display: flex;
  align-items: center;
}
.topnav__nameinfo h5 {
  font-size: 1.5rem!important;
  font-weight: 600!important;
  margin: 0;
}
.topnav__breadcrumb div span {
  font-size: .95rem;
  color: #565674;
}
.topnav__breadcrumb div:last-child {
  color: #fff !important;
}
.topnav__fast ul {
  padding: 0;
  display: flex;
  margin: 0;
}
.topnav__fast ul li {
  display: flex;
  margin-left: 13px;
}
.unique__settings {
  background: #0d67c9;
  color: #fff !important;
  font-weight: 700 !important;
}
.unique__settings:hover {
    color: #0d67c9 !important;
}
.topnav__fast ul {
  padding: 0;
  display: flex;
}
.topnav__fast ul li {
  display: flex;
  margin-left: 13px;
}
.fast__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.5em + 1.5rem - 8px);
  width: calc(1.5em + 1.5rem - 8px);
  cursor: pointer;
  border: 1px solid #323248;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
  border-radius: 10px;
  text-decoration: none;
}
.fast__iconday {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.5em + 1.5rem - 8px);
  width: calc(1.5em + 1.5rem - 8px);
  cursor: pointer;
  border: 1px solid #e8e5dd;
  font-weight: 500;
  line-height: 1.5;
  color: #323248;
  border-radius: 10px;
  text-decoration: none;
}
.fast__icon:hover {
  color: #cdcdde;
  border-color: #323248;
  background-color: #323248!important;
}
.fast__icon svg path, .fast__icon svg rect, .fast__icon i {
  fill: #92929f;
  color: #92929f;
}
.fast__icon:hover svg rect {
  fill: #0d67c9;
}
.fast__icon:hover svg path {
  fill: #0d67c9;
}
.fast__icon:hover i {
  color: #0d67c9;
}

.fast__iconday:hover {
  color: #cdcdde;
  border-color: #f8f6f2;
  background-color: #f8f6f2!important;
}
.fast__iconday svg path, .fast__iconday svg rect, .fast__iconday i {
  fill: #92929f;
  color: #92929f;
}
.fast__iconday:hover svg rect {
  fill: #0d67c9;
}
.fast__iconday:hover svg path {
  fill: #0d67c9;
}
.fast__iconday:hover {
  color: #0d67c9;
}
.icon__menu {
  display: flex;
  align-items: center;
}
.icon__menu span {
  font-size: 24px;
}
.icon__menuday span {
  color: #030303;
}
.icon__menunight span {
  color: #fff;
}
.breadcrumb__ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding: 0;
  margin-top: 0;
}
.breadcrumb__itemday {
  color: #92929f;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  display: flex;
  border: 1px solid #efe9e1;
  border-radius: 10px;
  height: 37.59px;
  padding: 0 10px;
  cursor: pointer;
}

.breadcrumb__itemnight {
  color: #fff;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 600;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border: 1px solid #323248;
  border-radius: 10px;
  height: 37.59px;
  cursor: pointer;
}

.breadcrumb__active a {
  color: #0d67c9;
  border-color: #0d67c9;
}
.breadcrumb__itemday:hover {
  color: #0d67c9;
  margin-right: 10px;
  border-color: #f8f6f2;
  background-color: #f8f6f2!important;

}
.breadcrumb__itemnight:hover {
  color: #0d67c9;
  margin-right: 10px;
  border-color: #323248;
  background-color: #323248!important;

}
.breadcrumb__li {
    display: flex;
}
.breadcrumb__li a::after {
  content: ' | ';
}
.breadcrumb__li a:last-child::after {
  content: '';
}
.breadcrumb__li::marker {
  display: none;
}
.top__day {
  background: #fff;
  transition: opacity .1s ease-out;
}
.top__night {
  background: #080c24ff;
  transition: opacity .1s ease-out;
}
.pop__box {
  width: 325px;
  margin-left: auto;
  background: white;
  border-radius: 13px;
  margin-right: 65px;
  margin-top: 60px;
}
.pop__header {
  text-align: center;
  padding: 20px 0;
  border-radius: 10px 10px 0 0;
}
.pop__headerday {
  background-image: linear-gradient(to right top, #0d67c9, #1c6ecb, #2976cd, #357dce, #4084cf, #4084cf, #4084cf, #4084cf, #357dce, #2976cd, #1c6ecb, #0d67c9);
}
.pop__headernight {
  background-image: linear-gradient(to right top, #0c0e1d, #0c0e22, #0c0e27, #0b0e2b, #0b0e30);
}
.pop__header h5 {
  color: #fff;
  font-size: 17.55px;
  font-weight: 500;
  margin-bottom: 5px;
}
.pop__header span {
  background: #0d67c9;
  border-radius: 0.35rem;
  padding: 2px 8px;
}
.pop__center {
  width: 100%;
}
.pop__centerday {
    background: #ffffff;
}
.pop__centernight {
    background: #1e1e2d;
}
.pop__footer {
  text-align: center;
  padding: 10px 0;
  border-radius: 0 0 10px 10px;
}
.pop__footerday {
    background: #ffffff;
}
.pop__footernight {
    background: #1e1e2d;
}
.pop__footer a {
  cursor: pointer;
  color: #6d6d80;
}
.pop__footer a:hover {
  color: #0d67c9;
}
.popup__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 19.25px;
  text-decoration: none;
  cursor: pointer;

}
.popup__item:hover {
  background: rgba(133, 133, 133, 0.2);
}
.popup__item img {
  height: 1.8rem !important;
  width: auto;
  object-fit: contain;
}
.popup__item span {
  color: #cdcdde;
  font-size: 14.95px;
  margin-top: 10px;
}
.popup__item p {
  color: #474761;
}
.popup1__day {
  border-bottom: 1px solid #e8e5dd;
  border-right: 1px solid #e8e5dd;
}
.popup1__night {
  border-bottom: 1px solid #2b2b40;
  border-right: 1px solid #2b2b40;
}
.popup2__day {
  border-bottom: 1px solid #e8e5dd;
}
.popup2__night {
  border-bottom: 1px solid #2b2b40;
}
.popup2 {
  border-bottom: 1px solid #2b2b40;
}
.popup3 {
  border-right: 1px solid #2b2b40;
  border-bottom: 1px solid #2b2b40;
}
.popup4 {
  border-bottom: 1px solid #2b2b40;
}
.pop__allday {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  position: fixed;
  background: rgba(0,0,0,0.1);
  width: 100%;
  height: 100%;
}
.pop__allnight {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  position: fixed;
  background: rgba(0,0,0,0.1);
  width: 100%;
  height: 100%;
}
.oneid__day {
    background: #fff;
}
.oneid__night {
    background: #929aff40;
}

.flag {

    .el-dropdown-link {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .flag_img {
        width: 30px;
    }

    .el-icon--right {
        margin-left: 3px;
        font-size: 10px;
        color: #fff;
        margin-right: -2px;
    }
}

.flag_img2 {
    width: 31px;
}

.flag_ul {
    .el-dropdown-menu__item {
        border-bottom: 1px solid #f1f5f8;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .el-dropdown-menu__item img {
        margin-right: 5px;
    }
}

.flag_ul li:nth-child(2n + 2) {
    border-bottom: none;
}
.oneid__img {
    display: inline-block;
    border-radius: 15px;
    padding: 5px 10px;
}
.oneid__img img {
    width: auto;
    height: 35px;
}
.profile__box {
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    width: max-content;
    border-radius: 10px;
    padding: 5px 10px;
    margin-top: 105px;
    width: 150px;
    margin-right: 20px;
  }
  // .profile__boxday {
    // box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    // background: rgb(255, 255, 255);
  // }
  .profile__boxnight {
    border: 1px dashed #323248 !important;
    background: #1E1E2D;
  }
  .profile__box h5 {
    margin: 0;
    font-size: 13px;
  }
  .profile__box span {
    font-size: 11px;
    text-align: left;
  }
  .haeder-menu{
    padding-top: 10px;
  }
  .mm__bonus_amount {
    font-size: 17px;
    padding: 7px 10px;
    color: rgb(35, 216, 35);
    white-space: nowrap ;
  }
  // .haeder-menu a {
  //   padding: 10px;
  //   color: #000;
  // }

</style>

import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import { model } from "./properties/model";

export const mutations = {
    SET_LIST: (state, clients) => { state.list = clients; },
    SET_TYPES: (state, types) => (state.types = types),
    SET_INVENTORY: (state, clients) => (state.inventory = clients),
    SET_DEALS: (state, deals) => (state.deals = deals),
    SET_CLIENT_ADDRESSES: (state, clientAddresses) => (state.clientAddresses = clientAddresses),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => { state.pagination[pagination.key] = pagination.value },
    UPDATE_COLUMN: (state, obj) => { state.columns[obj.key].show = obj.value },
    UPDATE_SORT: (state, sort) => { state.sort[sort.column] = sort.order },
    SET_MODEL: (state, client) => { state.model = client },
    EMPTY_MODEL: (state) => { state.model = JSON.parse(JSON.stringify(model)); },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    EMPTY_LIST: (state) => { state.list = []; }
};

export const getters = {
    token: state => state.token,
    phone: state => state.phone,
    email: state => state.email,
    name: state => state.name,
    role: state => state.role,
    slug: state => state.slug,
    permissions: state => { return (state.role) ? state.role.permissions : state.role.permissions },
    expires_in: state => state.expires_in,
    status: state=>state.status, 
    user: state=>state.user,
    register_rules: state=>state.register_rules,  
    login_rules: state=>state.login_rules,
    account_rule: state=>state.account_rule,
    password_reset_rules: state=>state.password_reset_rules,
    new_password_setting_rules: state=>state.new_password_setting_rules,
    passport_rules: state=>state.passport_rules,
    settings: state=>state.settings, 
};

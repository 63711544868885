import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    tracking_code : {
        show: true,
        title: i18n.t('message.tracking_code'),
        sortable: true,
        column: 'tracking_code'
    },
    client : {
        show: true,
        title: i18n.t('message.client'),
        sortable: true,
        column: 'client'
    },
    sender : {
        show: true,
        title: i18n.t('message.sender'),
        sortable: true,
        column: 'sender'
    },
    sender_address : {
        show: true,
        title: i18n.t('message.sender_address'),
        sortable: true,
        column: 'sender_address'
    },
    reciever : {
        show: true,
        title: i18n.t('message.reciever'),
        sortable: true,
        column: 'reciever'
    },
    reciever_address : {
        show: true,
        title: i18n.t('message.reciever_address'),
        sortable: true,
        column: 'reciever_address'
    },

    phone : {
        show: true,
        title: i18n.t('message.phone'),
        sortable: true,
        column: 'phone'
    },
    direction_tariff : {
        show: true,
        title: i18n.t('message.direction'),
        sortable: true,
        column: 'direction_tariff'
    },
    begin_date : {
        show: false,
        title: i18n.t('message.begin_date'),
        sortable: true,
        column: 'begin_date'
    },
    end_date : {
        show: false,
        title: i18n.t('message.end_date'),
        sortable: true,
        column: 'end_date'
    },
    reason : {
        show: false,
        title: i18n.t('message.reason'),
        sortable: true,
        column: 'reason'
    },
 

    products: {
        show: true,
        title: i18n.t('message.products'),
        sortable: true,
        column: 'products'
    },
    
    comment : {
        show: false,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },

    all_weight : {
        show: true,
        title: i18n.t('message.all_weight'),
        sortable: false,
        column: 'all_weight'
    },

    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },

};

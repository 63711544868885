import request from './../utils/request'

export function getColumnSetting(data) {
    return request({
        url: '/appColumnSettings/getColumnSetting',
        method: 'post',
        data
    })
}

export function updateColumnSetting(data) {
    return request({
        url: '/appColumnSettings/updateColumnSetting',
        method: 'post',
        data
    })
}

export function deleteAppColumnSetting(data) {
    return request({
        url: '/appColumnSettings/deleteAppColumnSetting',
        method: 'post',
        data
    })
}

import Vue from 'vue'
import store from '.././store';






import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.prototype.$kg_to_pound = parseFloat(2.20462);
Vue.prototype.$sm_to_inch = parseFloat(0.3937);
/**
 * Element UI
 */
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ru-RU'
Vue.use(ElementUI, { locale });
/**
 * end element ui
 */

/**
 * For alert
 */
import { notify, error__message } from './index'
Vue.prototype.$alert = notify;
Vue.prototype.$error_message = error__message;
/**
 * office
 */
import { openOffice , openOfficeForTemplate } from './index'
Vue.prototype.$openOffice = openOffice
Vue.prototype.$openOfficeForTemplate = openOfficeForTemplate

/**
 * For multi languages
 */
import VueI18n from 'vue-i18n'
Vue.use(VueI18n);

/**
 * money format
 */
import money from 'v-money'
import { mapGetters } from 'vuex';
Vue.use(money, { precision: 1, decimal: ',', thousands: ' ', masked: false, prefix: "", suffix: " сум" });

/**
 * Gloabl mixin
 */
Vue.mixin({
    data() {
        return {
            date_format: 'yyyy-MM-dd',
            date_time_format: 'yyyy-MM-dd HH:mm',
            global_variable_project_currency_symbol: process.env.MIX_CURRENCY,
        }
    },
    computed: {
        ...mapGetters({
            auth_name: 'name',
            role: 'role'
        })
    },
    methods: {
        print(id) {
            var mywindow = window.open('', 'PRINT', 'left=0,top=0,height=800,width=800');

            mywindow.document.write('<html><head><title>' + document.title + '</title>');
            mywindow.document.write('</head><body >');
            mywindow.document.write(document.getElementById(id).innerHTML);
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();

            return true;
        },
        hasPermission: function(value) {
            let temp = false;
            const role = this.role
            if (role && role != null && role != undefined && role.permissions) {
                if (value && value instanceof Array && value.length > 0) {
                    temp = true
                    value.forEach(element => {
                        temp = temp * role.permissions.some(perm => perm.slug === element)
                    });
                } else {
                    temp = role.permissions.some(perm => perm.slug === value)
                }
            }
            return temp;
        }
    },
});

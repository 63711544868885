import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import { i18n } from '@/utils/i18n'
import 'element-ui/lib/theme-chalk/index.css'
import "../public/css/main.css"
import "../public/fonts/fonts/fontawsome/css/all.min.css"
import locale from 'element-ui/lib/locale/lang/en'
import VueApexCharts from 'vue-apexcharts'
import './permissions'; // access permission
import './directive/permission/index';
import '@/utils/loader';
import * as filters from './filters'; // global filters

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.use(VuePhoneNumberInput);


Vue.config.productionTip = false
Vue.use(ElementUI, { locale })


window._ = require('lodash');
try {
    window.$ = window.jQuery = require('jquery');
} catch (e) {}

/**
 * Load all components
 */

const files = require.context('./components/', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

/**
 * Register global utility filters
 */
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

import { StripeCheckout } from '@vue-stripe/vue-stripe';
import {  mapActions, mapGetters } from 'vuex';

export default {
    components: { StripeCheckout},
    data() {
        return {
            sessionId: null,
            public_key: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
        }
    },
    computed: {
        ...mapGetters({
            authUser: "auth/user",
        }),
    },
    created() {
        if (this.model != null) {           
          this.form = JSON.parse(JSON.stringify(this.model));         
        }
    },
    methods: {
        ...mapActions({
            createSessionForPayment: 'payment/createSessionForPayment'
        }),

        async payOut (deal) {
            this.loadingData = true;
            let placement = (this.filterForm && this.filterForm.placement) ? this.filterForm.placement : ''
            await this.createSessionForPayment({deal_id: deal.id, success_url: '/all_deals', cancel_url: '/all_deals', placement: placement})
                .then(res => {
                    this.sessionId = res.data.id;
                    if(this.sessionId){
                        setTimeout(() => {
                            if(this.$refs.checkoutRef){
                                // This will be redirected to Stripe's secure checkout page
                                this.$refs.checkoutRef.redirectToCheckout()
                                    .then(stripe_res => {
                                        this.loadingData = false;
                                    }).catch(stripe_err => {
                                        this.loadingData = false;
                                    });
                            }
                        }, 300);
                    }else{
                        this.loadingData = false;
                        this.$notify({
                            title: this.$t('message.error_m'),
                            type: "error",
                            offset: 130,
                            message: this.$t('message.something_went_wrong_try_again')
                        });
                    }
                })
                .catch(err => {
                    this.loadingData = false;
                    this.$alert(err);
                })
        },

        isClientPayer(deal){
            let can_pay = false;
            if(this.authUser.client_id == deal.payer_client_id){
                can_pay = true;
            }
            return can_pay;
        },

        isDealPaid(deal){
            let deal_paid = false;
            let total = deal.product_total + deal.total_services_price;
            // if(deal.cargo_type != 'parcel'){
            //     total += (deal.delivery_price * deal.all_weight)
            // }
            total = _.round(total, 2);
            if(total <= deal.paid_money && deal.paid_money > 0){
                deal_paid = true;
            }
            return deal_paid;
        },
    },
  }
  
import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    user_id : {
        show: true,
        title: i18n.t('message.referral_users'),
        sortable: true,
        column: 'user_id'
    },
    deal_id : {
        show: true,
        title: i18n.t('message.deal_id'),
        sortable: true,
        column: 'deal_id'
    },
    percentage : {
        show: true,
        title: i18n.t('message.percentage'),
        sortable: true,
        column: 'percentage'
    },
    money_amount : {
        show: true,
        title: i18n.t('message.money_amount'),
        sortable: true,
        column: 'money_amount'
    },
    // actual_money_amount : {
    //     show: true,
    //     title: i18n.t('message.actual_money_amount'),
    //     sortable: true,
    //     column: 'actual_money_amount'
    // },
    paid_for_deal: {
        show: true,
        title: i18n.t('message.paid_for_deal'),
        sortable: true,
        column: 'paid_for_deal'
    },
    created_at : {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};

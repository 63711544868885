import {i18n} from '@/utils/i18n';

export const password_reset_rules = {
    phone: [
        {
            required: true,
            message: i18n.t('message.please_enter_input', {input: i18n.t('message.phone')}),
            trigger: "blur",
        },
        {
            min: 7,
            max: 15,
            message: i18n.t('message.text_length_must_be', {min: 7, max: 15}),
            trigger: "blur",
        },
    ],
    email: [
        {
            required: true,
            message: i18n.t('message.please_enter_input', {input: i18n.t('message.email')}),
            trigger: "blur",
        },
        {
            type: 'email',
            message: i18n.t('message.input_correct_email'),
            trigger: "blur",
        },
    ],
}
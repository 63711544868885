import { show, getWithTariffPrice, updatePartnerPricesByTariff, updateProductPricesByTariff } from "@/api/products";

export const actions = {

    updateProductPricesByTariff({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateProductPricesByTariff(data)
                .then(res => {
                    commit('UPDATE_DEAL_PRODUCT_PRICES', res.data.result.data.newPriceList);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    updatePartnerPricesByTariff({ commit }, data) {
        return new Promise((resolve, reject) => {
            updatePartnerPricesByTariff(data)
                .then(res => {
                    commit('UPDATE_DEAL_PARTNER_PRICES', res.data.result.data.partnerTariffPrice);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getWithTariffPrice({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getWithTariffPrice(params).then(res => {
                commit("SET_MODEL", res.data.result.data.product);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.product);
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

};

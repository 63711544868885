export const getters = {
    list: state => state.list,
    types: state => state.types,
    deals: state => state.deals,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort, 
    client_address_rules: state => state.client_address_rules,  
    clientAddresses: state => state.clientAddresses,
  };
  
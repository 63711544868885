import request from '@/utils/request'

export function getRuleBySlug(params) {
    return request({
        url: `/registerRules/getRuleBySlug/${params.slug}`,
        method: 'get',
        params
    })
}

export function register(data) {
    return request({
        url: '/account/auth/register',
        method: 'post',
        data
    })
}

export function login(data) {
    return request({
        url: '/account/auth/login',
        method: 'post',
        data
    })
}

export function getAuth() {
    return request({
        url: '/account/auth/user',
        method: 'get',
    })
}

export function logout() {
    return request({
        url: '/account/auth/logout',
        method: 'post'
    })
}

export function passwordReset(data) {
    return request({
        url: `/account/auth/passwordReset/${data.email}/${data.phone}`,
        method: 'post',
        data
    })
}

export function saveNewPassword(data) {
    return request({
        url: `/account/auth/saveNewPassword/${data.token}`,
        method: 'post',
        data
    })
}

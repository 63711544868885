<template v-bind:key="unique_key" v-bind:name="unique_key">
    <div>
        <div class="d-flex">
            <el-select
                :value="selected"
                @input="dispatch"
                :placeholder="placeholder || ''"
                :disabled="disabled"
                filterable
                clearable
                :size="size"
                remote 
                :remote-method="searchInventoryWithLoad"
                class="w-100"
            >
                <div v-infinite-scroll="scrollLoad" infinite-scroll-disabled="disableLoad">
                    <el-option
                        v-for="(item, index) in inventoryItems"
                        :key="'inventoryItems-' + index"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                    <p v-loading="loadingData" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"></p>
                    <p class="el-select-dropdown__empty" v-if="noMore">{{$t('message.no_more_data')}}</p>
                </div>
            </el-select>
            <!-- <el-button v-if="create == true" @click="createRecipientDialog = true" class="ml-2" type="primary" size="medium" icon="el-icon-plus"></el-button> -->
        </div>

        <!-- <div>
            <el-dialog class="mobil-100" :title="$t('message.reciever')" width="80%" :visible.sync="createRecipientDialog">
                <create-recipient></create-recipient>
            </el-dialog>
        </div> -->
    </div>
</template>
<script>

// import createRecipient from '@/views/recievers/create-recipient.vue';

import { mapActions } from "vuex";
export default {
    // components: {createRecipient},
    props: {
        size: {
            default: "large",
        },
        disabled: {
            default: false,
        },
        placeholder: {
            default: null,
        },
        id: {
            default: null,
        },
        query: {
            default: Object,
            type: Object
        },
        required: {
            default: Array,
            type: Array
        },
        action_key: {
            default: '',
        },
        unique_key: {
            default: '',
        },
        select_single: {
            default: true,
        },
        create: {
            default: false,
        },
    },
    watch: {
        id: {
            handler: function () {
                this.selected = this.id;
            },
            immediate: true,
        },
        watchQuery: {
            handler: function () {
                if(this.checkValid){
                    if (_.isFunction(this.updateInventory)) {
                        this.selected = null;
                        this.updateInventory({action_key: this.action_key, ...this.query});
                    }
                }
            },
        }
    },
    data() {
        return {
            selected: null,
            loadingData: false,
            filterText: '',
            inventoryItems: [],
            createRecipientDialog: false,
        };
    },
    created(){
        this.debouncedUpdateInventory =  _.debounce(this.updateInventory, 1000);
        this.debouncedUpdateInventory({action_key: this.action_key, ...this.query}); 
    },
    computed: {
        watchQuery() {
            return (JSON.stringify(this.query ) +'aa');
        },
        noMore () {
            return (this.inventoryItems.length % 30) !== 0;
        },
        disableLoad () {
            return this.loadingData || this.noMore;
        },
        checkValid() {
            let valid = false;
            if(this.query){
                this.required.forEach((element, index) => {
                    if(!this.query[element]){
                        return;
                    }else if((index + 1) === this.required.length){
                        valid = true;
                    }
                });
            }
            return (valid || this.required.length === 0) ? true : false;
        }
    },
    methods: {
        ...mapActions({
            getInventoryItems: "inventories/getInventoryItems",
            addInventoryItems: "inventories/addInventoryItems",
        }),
        updateDataList(){
            this.loadingData = false;
            this.inventoryItems = this.$store.getters['inventories/inventoryItems'](this.action_key);
            if(this.inventoryItems.length === 1 && this.select_single){
                let id = this.inventoryItems[0].id;
                this.dispatch(id);
            }
        },
        updateInventory(query) {
            this.getInventoryItems({...query, selected_id: this.id})
                .then(res => {
                    this.updateDataList();
                })
                .catch(err => this.loadingData = false);
        },
        scrollLoad(){
            if(this.checkValid){
                this.loadingData = true;
                this.scrollLoadData(this);
            }
        },
        scrollLoadData: _.debounce(function(self) {
            let count = self.inventoryItems.length;
            self.addInventoryItems({action_key: self.action_key, skip: count, search: self.filterText, ...self.query})
                .then(res => {
                    let add_data = JSON.parse(JSON.stringify(res.result.data[self.action_key]));
                    self.inventoryItems = self.inventoryItems.concat(add_data);
                    self.loadingData = false;
                })
                .catch(err => {
                    self.loadingData = false
                });
        }, 500), 
        dispatch(e) {
            this.$emit("input", e);
            this.selected = e;
        },
        searchInventoryWithLoad(val = ''){
            if(this.checkValid){
                this.loadingData = true;
                this.searchInventory(val, this);
            }
        },
        searchInventory: _.debounce(function(val, self) {
            self.filterText = val;
            if(val){
                self.updateInventory({action_key: self.action_key, search: val, ...self.query})
            }else{
                self.updateInventory({action_key: self.action_key, ...self.query})
            }
        }, 500), 
    },
};
</script>

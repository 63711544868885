import {i18n} from '@/utils/i18n';

export const new_password_setting_rules = {
    email: [
        {
            required: true,
            message: i18n.t('message.please_enter_input', {input: i18n.t('message.email')}),
            trigger: "blur",
        },
        {
            type: 'email',
            message: i18n.t('message.input_correct_email'),
            trigger: "blur",
        },
    ],
    password: [
        {
            required: true,
            message: i18n.t('message.please_enter_input', {input: i18n.t('message.new_password')}),
            trigger: "blur",
        },
        {
            min: 4,
            message: i18n.t('message.length_must_be_min', {number: 4}),
            trigger: "blur",
        },
    ],
    password_confirmation: [
        {
            required: true,
            message: i18n.t('message.please_do', {do: i18n.t('message.confirm_password')}),
            trigger: "blur",
        },
        {
            min: 4,
            message: i18n.t('message.length_must_be_min', {number: 4}),
            trigger: "blur",
        },
    ],

}
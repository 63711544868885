import request from '@/utils/request'

export function index(params) {
    return request({
        url: '/clients',
        method: 'get',
        params: params
    })
}

export function show(id) {
    return request({
        url: `/clients/${id}`,
        method: 'get',
    })
}

export function profile(id) {
    return request({
        url: `/clients/profile/${id}`,
        method: 'get'
    })
}

export function createCabinetClient(data) {
    return request({
        url: `/clients/createCabinetClient`,
        method: 'post',
        data
    })
}

export function updateProfile(data) {
    return request({
        url: `/clients/updateProfile/${data.id}`,
        method: 'put',
        data
    })
}

export function createClientAddress(data) {
    return request({
        url: `/clients/createClientAddress/${data.client_id}`,
        method: 'post',
        data
    })
}

export function deleteClientAddress(id) {
    return request({
        url: `/clients/deleteClientAddress/${id}`,
        method: 'delete',
    })
}

export function destroy(id) {
    return request({
        url: `/clients/${id}`,
        method: 'delete',
    })
}

export function getTypes(params) {
    return request({
        url: '/clients/get/types',
        method: 'get',
        params
    })
}
export const state = {
    clients: [],
    filials: [],
    products: [],
    countries: [],
    regions: [],
    cities: [],
    client_status: [],
    client_addresses: [],
    directionTariffs: [],
    reasons: [],

};

export var barchart = {
    series: [
        { name:'Общее расстояние', data: [12,21,35,21,65] }, 
        { name: 'Оплачиваемая дистанция', data: [12,55,21,87,54] }
    ],
    chartOptions: {
        chart: { type: 'bar', height: 430 },
        title: {
            text: 'Лучшие водители',
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize:  '14px',
              fontWeight:  'bold',
              fontFamily:  'Arial',
              color:  '#263238'
            },
        },
        plotOptions: { bar: { horizontal: true, dataLabels: { position: 'top' } } },
        dataLabels: {
            enabled: true,
            offsetX: -6,
            style: { fontSize: '12px', colors: ['#fff'] }
        },
        stroke: { show: true, width: 1, colors: ['#fff'] },
        tooltip: { shared: true, intersect: false },
        xaxis: {
            categories: ['Driver 1','Driver 2','Driver 3','Driver 4','Driver 5'],
        },
    },
};

<template>
    <div v-loading="loading">
        <ul class="menu-mabil-home">
            <li v-for="(sort, index) in filterOptions" @click="updateActive(sort.action)" >
                <router-link :to="{ name: 'tableHome', params: { placement: sort.action }}">
                    <span> {{ sort.title }}</span>
                    <b>{{ sort.count }}</b>
                    <i class="el-icon-arrow-right"></i>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters, mapActions} from 'vuex'
export default {
    name: 'filterTable',
    data: () => ({
        active: 'new_deals',
        loading: false,
    }),
    watch: {
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            filterOptions: "deals/filterOptions",
        }),
    },
    methods: {
        updateActive (action) {
            this.$emit('updateActiveFilter', action)
        },
        
    }
}
</script>

<style>
.mew-style-khan{
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 99%;
}
</style>

import { model } from "./properties/model";

export const mutations = {
    SET_LIST: (state, deals) => { state.list = deals; },

    SET_FILTER_OPTIONS: (state, filterOptions) => {              
        state.filterOptions = filterOptions;        
    },

    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },

    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, deal) => {
        state.model = deal
    },

    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));        
        state.form = JSON.parse(JSON.stringify(model));        
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    EMPTY_LIST: (state) => {
        state.list = [];
    }
};
